import FileSaver from 'file-saver'
import _ from 'lodash'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { v4 as uuidv4 } from 'uuid'

import { RootState, TypedDispatch } from '..'
import { fetchDevicesAction, upsertDeviceAction } from '../actions/deviceManagement'
import config from '../config'
import { clearSession, setSessionId, useSession } from '../lib/api'
import b64 from '../lib/b64'
import {
  closeSession,
  createOrGetTowingFormStateFromAPI,
  deleteCompositionFromDB,
  deletePersonnelFromDB,
  deleteSearchedShiftFromDB,
  fetchCalendarURLFromAPI,
  fetchCompositionsFromAPI,
  fetchContactsFromAPI,
  fetchCrewNoticeFromAPI,
  fetchCrewNoticesFromAPI,
  fetchHandlingStationsFromAPI,
  fetchMultipleTowingVehiclesFromAPI,
  fetchReasonCodes,
  fetchShiftNoticesFromAPI,
  fetchTimetableFromAPI,
  fetchTowingVehiclesFromAPI,
  fetchTrainPunctualityFromAPI,
  getAssembliesFromAPI,
  getCompositionSearchHistoryFromDB,
  getContactsFromDB,
  getEnergyEfficiencyFromAPI,
  getFeedbacks,
  getFindings,
  getNewsFromAPI,
  getObservationMessagesFromDB,
  getPersonnelFromAPI,
  getPersonnelFromDB,
  getPersonnelHistoryFromDB,
  getResponseReads,
  getRollingGuidesFromAPI,
  getSchedule,
  getSearchedShiftFromDB,
  getSearchedShiftSearchHistoryFromDB,
  getSessionAsUser,
  getSessionWithExchangeToken,
  getSessionWithRole,
  getShift,
  getShiftById,
  getShiftDoneTasks,
  getShiftInformationFromAPI,
  getShiftSignInStatuses,
  getTowingFormByIdFromAPI,
  getTowingFormByIdFromDB,
  getTowingFormFromAPI,
  getTowingFormStateByIdFromAPI,
  getTowingFormStateByIdFromDB,
  getTowingFormStateFromDB,
  getTowingVehiclePatternsFromAPI,
  hasUpdates,
  postCompositionConfirmation,
  postVersionInfo,
  readFeedback,
  recheckSession,
  refreshSchedule,
  refreshShift,
  saveObservationMessageToDB,
  saveTowingFormStateToAPI,
  searchedCompositionFromDB,
  sendCrewNoticeAckToAPI,
  sendCustomerFeedbackToAPI,
  sendDeviationAmendmentToAPI,
  sendFeedback as sendFeedbackData,
  sendObservationMessageToAPI,
  sendTowingAuditMessage,
  shiftSignIn,
  updateOrSaveTowingFormStateToDB,
  updateOrSaveTowingFormToDB,
} from '../lib/data'
import {
  Conflict,
  ErrorProps,
  getApiErrorInput,
  getErrorString,
  isConflictError,
} from '../lib/errors'
import { error } from '../lib/logger'
import moment from '../lib/moment-fi'
import { isCommuterUser, isESalliUser } from '../lib/roleUtils'
import { unixTimestamp } from '../lib/time'
import { updatePersonnelGroup, updateServiceDriverRole, updateSession } from '../reducers/User'
import { findingsSelector, hasExpiredFindings, selectOperatingDateForTask } from '../Selectors'
import {
  AppState,
  Compositions,
  Feedback,
  Locomotive,
  Moment,
  ObservationMessage,
  Personnel,
  Schedule,
  Shift,
  SignInStatus,
  TaskParams,
  Timestamp,
  TowingFormStepOrSelection,
} from '../types'
import { FetchHandlingStations, TrainParams } from '../types/App'
import {
  CrewNotice,
  CrewNoticeAck,
  EnergyEfficiency,
  News,
  RollingGuideData,
  SearchDeleteParams,
  Session,
  ShiftSearchSource,
  SignedIn,
  TaskDone,
  TimetableParams,
  TowingAuditMessage,
  TowingFormState,
} from '../types/Input'
import { AmendmentData, FindingsState } from '../types/States'
import { sendAmendmentError, startSendingAmendment, updateAmendment } from './amendment'
import { assemblyLoadError, startLoadingAssemblies, updateAssemblies } from './assembly'
import { calendarError, startFetchingCalendar, updateCalendar } from './calendar'
import { causesFetchingError, startFetchingCauses, updateCauses } from './causes'
import {
  compositionsFetchingError,
  setCompositionFromHistory,
  startCheckOrInspection,
  startFetchingCompositions,
  updateCompositionHistory,
  updateCompositions,
} from './compositions'
import { updateContacts } from './contacts'
import {
  batchUpdateCrewNotice,
  crewNoticeError,
  crewNoticeErrorAll,
  startFetchingAllCrewNotices,
  startFetchingCrewNotice,
  updateCrewNotice,
  updateCrewNoticeAck,
} from './crewNotice'
import { startSendingCustomerFeedback, updateCustomerFeedback } from './customerFeedback'
import {
  energyEfficiencyError,
  startFetchingEnergyEfficiency,
  updateEnergyEfficiency,
} from './energyEfficiency'
import { clearFeedbacks, updateFeedback } from './feedback'
import { findingsFetchingError, startFetchingFindings, updateFindings } from './findings'
import {
  handlingStationsError,
  startFetchingStations,
  updateHandlingStations,
} from './handlingStations'
import { newsError, startFetchingNews, updateNews } from './news'
import {
  setObservationMessageError,
  startSendingObservationMessage,
  updateObservationMessages,
} from './observationMessages'
import {
  fetchPersonnel,
  personnelLoadError,
  setPersonnelFromHistory,
  updatePersonnel,
  updatePersonnelHistory,
} from './personnel'
import {
  phoneContactsFetchingError,
  startFetchingPhoneContacts,
  updatePhoneContacts,
} from './phoneContacts'
import { punctualityError, startFetchingTrainPunctuality, updatePunctuality } from './punctuality'
import { markAsRead } from './response-read'
import { updateRollingGuides } from './rollingGuides'
import { clearShifts, reloadSchedule, updateSchedule } from './shift'
import {
  fetchSearchedShift,
  searchedShiftsLoadError,
  searchShifts,
  updateSearchedShifts,
  updateSelectedShift,
  updateShiftHistory,
} from './shift-search'
import { clearShiftDetails, reloadShift, shiftLoadError, updateShift } from './shiftDetails'
import { shiftNoticeError, startFetchingShiftNotices, updateShiftNotices } from './shiftNotices'
import { markShiftTasksDone } from './shiftpage'
import { startSigningIn, updateSignInStatus, updateSignInStatuses } from './sign-in-status'
import { newAppVersion, toggleAdminBar } from './system'
import { startFetchingTimetable, timetableError, updateTimetable } from './timetable'
import { startFetchingTowingForm, towingFormError, updateTowingForm } from './towingForm'
import {
  startFetchingTowingFormState,
  startSavingTowingFormState,
  startSavingTowingStep,
  towingFormStateError,
  updateTowingFormState,
} from './towingFormState'
import {
  clearTowingVehicle,
  refetchTowingVehicle,
  startFetchingTowingVehicle,
  towingError,
  updateTowingVehicle,
  updateTowingVehicles,
} from './towingVehicle'
import {
  setVehiclePatternsError,
  startFetchingVehiclePatterns,
  updateVehiclePatterns,
} from './towingVehiclePatterns'

export type ChangeViewFunction = (view?: string) => void

type ChangeCallbackFunction = () => void
interface ExtendedTowingAuditMessage extends TowingAuditMessage {
  vehicleType: string
  vehicleNumber: string
}

const logError = (err: ErrorProps): void => {
  const s = err ? getErrorString(err) : ''
  error(s, err)
}

export const fetchRouteShift = (): ThunkAction<void, RootState, null, Action<string>> => {
  return (dispatch: TypedDispatch, getState: () => RootState): void => {
    const { found, shifts } = getState()
    const shiftId = found.match.params ? found.match.params.shiftId : ''
    const shift: Shift | null = shifts.byId[shiftId]
    if (shift) {
      dispatch(
        fetchShift(shiftId, {
          startDateTime: shift.startDateTime,
          endDateTime: shift.endDateTime,
          preparation: shift.preparation,
          wrapUp: shift.wrapUp,
        })
      )
      dispatch(fetchShiftNotices())
    }
  }
}

export const fetchShift = (
  shiftId: string,
  headOptions: {
    signInStatus?: SignedIn
    startDateTime: Timestamp
    endDateTime: Timestamp
    preparation: string
    wrapUp: string
  }
): ThunkAction<void, RootState, null, Action<string>> => {
  return (dispatch: TypedDispatch): void => {
    dispatch(reloadShift(shiftId))

    getShift(shiftId, headOptions)
      .then((shift: Shift) => {
        dispatch(updateShift(shift))
        return shift
      })
      .then((shift: Shift) => {
        if (shift.crewNotices) {
          dispatch(batchUpdateCrewNotice(shift.crewNotices))
        }
        return shift
      })
      .then((shift: Shift) => {
        shift.contacts && dispatch(updateContacts(shift.contacts))
        return shift
      })
      .then((shift: Shift) => {
        shift.assemblies && dispatch(updateAssemblies(shift.assemblies))
        return shift
      })
      .then((shift: Shift) => {
        shift &&
          getObservationMessagesFromDB().then((messages) => {
            dispatch(updateObservationMessages(messages))
          })
        return shift
      })
      .then((shift: Shift) => {
        return parseTrainsAndFetchPunctuality(shift, dispatch)
      })
      .catch((err) => {
        dispatch(shiftLoadError(getErrorString(err, 'shift')))
      })
  }
}

export const refetchShift = (shift: Shift, session: Session) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(reloadShift(shift.id))
    dispatch(refetchSchedule(session))

    refreshShift(shift)
      .then((shift: Shift) => {
        dispatch(updateShift(shift))
        shift.crewNotices && dispatch(batchUpdateCrewNotice(shift.crewNotices))
        shift.contacts && dispatch(updateContacts(shift.contacts))
        parseTrainsAndFetchPunctuality(shift, dispatch)
      })
      .catch((err) => {
        dispatch(shiftLoadError(getErrorString(err)))
        dispatch(checkSession(session))
      })
  }
}

export const signInToShift = (shift: Shift) => {
  return (dispatch: TypedDispatch): void => {
    const id = shift.id
    dispatch(startSigningIn(id))
    shiftSignIn(id, shift.startDateTime, moment().format('YYYY-MM-DDTHH:mm:ss[Z]'))
      .then((signInStatus) => {
        dispatch(updateSignInStatus(signInStatus))
      })
      .catch(logError)
  }
}

export const fetchSchedule = (userNumber: string) => {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(reloadSchedule())

    try {
      const schedule = await getSchedule(userNumber)
      dispatch(updateServiceDriverRole(schedule.serviceDriver))
      dispatch(refreshSignInStatus())

      dispatch(updateSchedule(schedule))
      dispatch(updatePersonnelGroup(schedule.personnelGroup))

      // Fetch shift for current route
      dispatch(fetchRouteShift())
    } catch (error) {
      logError(error as ErrorProps)
    }
  }
}

export const refetchSchedule = (user: Session) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(reloadSchedule())

    refreshSchedule(user.number)
      .then((schedule: Schedule) => {
        if (schedule.error) {
          dispatch(checkSession(user))
        }
        dispatch(updateServiceDriverRole(schedule.serviceDriver))
        dispatch(refreshSignInStatus())

        dispatch(updateSchedule(schedule))
        dispatch(updatePersonnelGroup(schedule.personnelGroup))
      })
      .catch((err) => {
        dispatch(checkSession(user))
        return logError(err)
      })
  }
}

const refreshSignInStatus = () => {
  return (dispatch: TypedDispatch): void => {
    getShiftSignInStatuses()
      .then((statuses: Array<SignInStatus>) => {
        dispatch(updateSignInStatuses(statuses))
      })
      .catch(logError)
  }
}

export const fetchEnergyEfficiencyData = () => {
  return (dispatch: TypedDispatch): void => {
    dispatch(startFetchingEnergyEfficiency())
    getEnergyEfficiencyFromAPI()
      .then((efficiencyData: EnergyEfficiency) => {
        dispatch(updateEnergyEfficiency(efficiencyData, 'overview'))
      })
      .catch((err) => {
        dispatch(
          energyEfficiencyError(
            err instanceof Error ? err.message : 'Fetching energy efficiency failed'
          )
        )
        logError(err)
      })
  }
}

export const fetchRollingGuideData = () => {
  return (dispatch: TypedDispatch): void => {
    getRollingGuidesFromAPI()
      .then((rollingGuides: RollingGuideData) => {
        dispatch(updateRollingGuides(rollingGuides))
      })
      .catch(logError)
  }
}

export const fetchNews = () => {
  return (dispatch: TypedDispatch): void => {
    dispatch(startFetchingNews())
    getNewsFromAPI()
      .then((news: Array<News>) => {
        dispatch(updateNews(news))
      })
      .catch((err) => {
        logError(err)
        dispatch(newsError(err))
      })
  }
}

export const fetchUserData = (session: Session) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(fetchSchedule(session.number))

    if (session.number !== session.originalNumber) {
      getSessionWithRole(session).then((updatedSession) => {
        dispatch(updateSession(updatedSession))
      })
    }

    if (session.driver || session.logistics_driver) {
      dispatch(fetchEnergyEfficiencyData())
      dispatch(fetchRollingGuideData())
    }
    if (session.driver || session.commuter_driver || session.commuter_manager) {
      dispatch(fetchNews())
    }

    if (isESalliUser(session)) {
      dispatch(refreshSignInStatus())
    }

    getShiftDoneTasks().then((dones) => {
      const byShift: Record<string, Array<TaskDone>> = _.groupBy(dones, 'shiftId')
      _.keys(byShift).forEach((shiftId) => {
        const indexes = byShift[shiftId].map((t: TaskDone) => t.index)
        dispatch(markShiftTasksDone(shiftId, indexes))
      })
    })

    if (config.features.shiftFeedback && isESalliUser(session)) {
      getResponseReads()
        .then((reads) =>
          reads.forEach((read) => {
            dispatch(markAsRead(read.id, read.state === 'read'))
          })
        )
        .catch(logError)

      getFeedbacks((feedbacks) => {
        feedbacks.forEach((fb) => {
          dispatch(updateFeedback(fb))
        })
        return Promise.resolve()
      })
    }
    if (isCommuterUser(session)) {
      dispatch(fetchCrewNotices())
    }
    // TODO: look into removing
    dispatch(fetchShiftNotices())
  }
}

export const sendFeedback = (feedback: Feedback) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(updateFeedback({ ...feedback, loading: true }))

    sendFeedbackData(feedback)
      .then((feedback) => {
        dispatch(updateFeedback(feedback))
      })
      .catch(logError)
  }
}

export const markFeedbackAsRead = (feedbackId: string) => {
  return (dispatch: TypedDispatch): void => {
    // Optimistic update, roll back on error
    dispatch(markAsRead(feedbackId))

    readFeedback(feedbackId).catch(() => dispatch(markAsRead(feedbackId, false)))
  }
}

const initSession =
  (session: Session) =>
  (dispatch: TypedDispatch): Session => {
    sendVersionInfo()
    dispatch(updateSession(session))

    dispatch(clearShifts())
    dispatch(clearShiftDetails())
    dispatch(clearFeedbacks())

    // Check if there is new version for app when user has logged in
    if (session.number) {
      hasUpdates().then((exists) => dispatch(newAppVersion(exists)))
      setSessionId(session.originalNumber)
    }

    if (session && session.token && !session.error) {
      dispatch(fetchUserData(session))
      dispatch(upsertDeviceAction())
      dispatch(fetchDevicesAction())
    }

    return session
  }

export const fetchSessionData = (exchangeToken: string) => {
  return (dispatch: TypedDispatch, getState: () => AppState): Promise<unknown> => {
    dispatch(
      updateSession({
        token: '',
        name: '',
        familyName: '',
        number: '',
        personnelGroup: '',
        serviceDriver: false,
        originalNumber: '',
        admin: false,
        read_admin: false,
        commuter_driver: false,
        commuter_manager: false,
        driver: false,
        logistics_driver: false,
        commuter_conductor: false,
        conductor: false,
        waiter: false,
        maintenance: false,
        other: false,
        created_at: unixTimestamp(),
        loading: true,
        error: '',
      })
    )

    return getSessionWithExchangeToken(exchangeToken)
      .then(useSession)
      .then((session: Session) => {
        initSession(session)(dispatch)
      })
  }
}

export const actAsUser = (
  number: string,
  actAsCommuter: boolean,
  actAsMaintenance: boolean,
  actAsServiceDriver: boolean,
  currentSession: Session,
  shouldToggleAdminBar = true
) => {
  return (dispatch: TypedDispatch, getState: () => AppState): Promise<void | Session> => {
    if (number) {
      dispatch(updateSession({ ...currentSession, loading: true }))
      if (shouldToggleAdminBar) {
        dispatch(toggleAdminBar())
      }
      return getSessionAsUser(
        number,
        actAsCommuter,
        actAsMaintenance,
        actAsServiceDriver,
        currentSession
      )
        .then(useSession)
        .then((session: Session) => {
          initSession(session)(dispatch)
        })
    } else if (number === '') {
      dispatch(updateSession({ ...currentSession, loading: true }))
      if (shouldToggleAdminBar) {
        dispatch(toggleAdminBar())
      }
      return getSessionAsUser(
        currentSession.originalNumber,
        actAsCommuter,
        actAsMaintenance,
        actAsServiceDriver,
        currentSession
      )
        .then(useSession)
        .then((session: Session) => {
          initSession(session)(dispatch)
        })
    }
    return Promise.resolve()
  }
}

export const logout = (dispatch: TypedDispatch) => {
  closeSession()
  clearSession()
  dispatch(clearShifts())
  dispatch(clearShiftDetails())
  dispatch(clearFeedbacks())
  dispatch(
    updateSession({
      token: '',
      name: '',
      familyName: '',
      number: '',
      personnelGroup: '',
      serviceDriver: false,
      originalNumber: '',
      admin: false,
      read_admin: false,
      commuter_driver: false,
      commuter_manager: false,
      driver: false,
      logistics_driver: false,
      commuter_conductor: false,
      conductor: false,
      waiter: false,
      maintenance: false,
      other: false,
      created_at: unixTimestamp(),
      loading: false,
      error: '',
    })
  )
}

export const checkSession = (session: Session) => {
  return (dispatch: TypedDispatch): void => {
    if (session.number) {
      recheckSession(session)
        .then(useSession)
        .then((session) => {
          dispatch(updateSession(session))
          if (!session.error) {
            dispatch(fetchUserData(session))
          }
        })
    }
  }
}

export const fetchPersonnelInformation = (
  date: string,
  trainNumber: string,
  changeView?: ChangeViewFunction | null
) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(fetchPersonnel())
    getPersonnelFromAPI(date, trainNumber)
      .then((personnel: Personnel) => {
        if (personnel && personnel.contacts) {
          dispatch(updateContacts(personnel.contacts))
        }
        if (personnel && personnel.trainNumber) {
          dispatch(updatePersonnel(personnel))
          if (changeView) {
            changeView()
          }
        } else {
          dispatch(personnelLoadError('personnelSearchError'))
        }
      })
      .catch(() => {
        dispatch(personnelLoadError('personnelSearchError'))
      })
  }
}

export const getPersonnelSearchHistory =
  () =>
  (dispatch: TypedDispatch): void => {
    getPersonnelHistoryFromDB().then((history) => {
      dispatch(updatePersonnelHistory(history))
    })
  }

export const personnelFromHistory =
  (train: Personnel, changeView: ChangeViewFunction) =>
  (dispatch: TypedDispatch): void => {
    dispatch(setPersonnelFromHistory(train))
    changeView()
  }

export const loadPersonnelFromHistory =
  (date: string, trainNumber: string) =>
  (dispatch: TypedDispatch): void => {
    dispatch(fetchPersonnel())
    getPersonnelFromDB(date, trainNumber)
      .then((personnel) => {
        if (personnel && personnel.length === 1) {
          dispatch(setPersonnelFromHistory(personnel[0]))
        } else {
          dispatch(personnelLoadError('personnelSearchError'))
        }
      })
      .catch(personnelLoadError)
  }

export const deletePersonnelFromHistory =
  (params: SearchDeleteParams) =>
  (dispatch: TypedDispatch): void => {
    if (params.trainNumber && params.date)
      deletePersonnelFromDB(params.trainNumber, params.date).then(() =>
        dispatch(getPersonnelSearchHistory())
      )
  }

export const fetchShifts =
  (
    dateInput: string,
    trainNumber: string,
    startStation: string,
    shiftId: string,
    searchSource: ShiftSearchSource | null,
    changeView?: ChangeViewFunction
  ) =>
  (dispatch: TypedDispatch): void => {
    dispatch(searchShifts())
    getShiftInformationFromAPI(dateInput, trainNumber, startStation, shiftId, searchSource)
      .then((shiftInformation) => {
        if (shiftInformation.error) {
          dispatch(
            searchedShiftsLoadError(
              shiftInformation.error ? shiftInformation.error : 'shiftInstructionsNotFound'
            )
          )
        } else if (shiftInformation.shifts && shiftInformation.shifts.length > 0) {
          dispatch(updateSearchedShifts(shiftInformation))
          if (changeView) changeView()
        } else {
          dispatch(searchedShiftsLoadError('shiftInstructionsNotFound'))
        }
      })
      .catch(() => {
        dispatch(searchedShiftsLoadError('shiftInstructionsNotFound'))
      })
  }

export const fetchShiftInformation =
  (shiftId: string, changeView?: ChangeViewFunction) => (dispatch: TypedDispatch) => {
    dispatch(fetchSearchedShift())
    getShiftById(shiftId, {
      startDateTime: '',
      endDateTime: '',
      preparation: '',
      wrapUp: '',
    })
      .then((shiftInfo) => {
        dispatch(updateSelectedShift(shiftInfo))
        if (changeView) changeView()
        return shiftInfo
      })
      .then((shift: Shift) => {
        shift.assemblies && dispatch(updateAssemblies(shift.assemblies))
        return shift
      })
      .then((shift: Shift) => {
        return parseTrainsAndFetchPunctuality(shift, dispatch)
      })
      .catch(() => {
        dispatch(searchedShiftsLoadError('selectedShiftNotFound'))
      })
  }

export const getSearchedShiftSearchHistory =
  () =>
  (dispatch: TypedDispatch): void => {
    getSearchedShiftSearchHistoryFromDB().then((history) => {
      dispatch(updateShiftHistory(history))
      history.forEach((shift) => {
        if (shift.assemblies) {
          dispatch(updateAssemblies(shift.assemblies))
        }
      })
    })
  }

export const searchedShiftFromHistory =
  (shift: Shift, changeView: ChangeViewFunction) =>
  (dispatch: TypedDispatch): void => {
    dispatch(updateSelectedShift(shift))
    changeView()
  }

export const deleteSearchedShiftFromHistory =
  (params: SearchDeleteParams) =>
  (dispatch: TypedDispatch): void => {
    if (params.shiftId && params.date)
      deleteSearchedShiftFromDB(params.shiftId, params.date).then(() =>
        dispatch(getSearchedShiftSearchHistory())
      )
  }

export const loadSearchedShiftFromHistory =
  (shiftId: string, date: string) =>
  (dispatch: TypedDispatch): void => {
    dispatch(fetchSearchedShift())
    getSearchedShiftFromDB(shiftId, date)
      .then((shift) => {
        if (shift && shift.length === 1) {
          dispatch(updateSelectedShift(shift[0]))
        } else {
          dispatch(searchedShiftsLoadError('selectedShiftNotFound'))
        }
      })
      .catch(searchedShiftsLoadError)
  }

export const fetchFindings =
  (equipments: Array<string>) =>
  (dispatch: TypedDispatch, getState: () => AppState): void => {
    // Check cache expiry and missing at this level, maybe?
    const findingsState = findingsSelector(getState()) as FindingsState
    if (!hasExpiredFindings(findingsState, equipments))
      // We have fresh data, no need to refresh
      return
    dispatch(startFetchingFindings(equipments))
    getFindings(equipments)
      .then((findings) => {
        dispatch(updateFindings({ findings }, equipments))
      })
      .catch(() => {
        dispatch(findingsFetchingError('fetchingFindingsFailed', equipments))
      })
  }

export const fetchContacts =
  () =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingPhoneContacts())
    getContactsFromDB()
      .then((contactsFromDB) => {
        if (contactsFromDB.length > 0 && contactsFromDB[0].contacts.length > 0) {
          dispatch(updatePhoneContacts(contactsFromDB[0].contacts))
          return contactsFromDB[0].contacts
        }
      })
      .catch(() => dispatch(phoneContactsFetchingError('fetchingContactsFailed')))

    fetchContactsFromAPI()
      .then((contactsFromAPI) => {
        if (contactsFromAPI.length && contactsFromAPI.length > 0) {
          dispatch(updatePhoneContacts(contactsFromAPI))
        } else {
          dispatch(phoneContactsFetchingError('fetchingContactsFailed'))
        }
      })
      .catch(() => {
        dispatch(phoneContactsFetchingError('fetchingContactsFailed'))
      })
  }

export const fetchHandlingStations: FetchHandlingStations =
  (date: string, trainNumber: string) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingStations())
    fetchHandlingStationsFromAPI(date, trainNumber)
      .then((stations) => {
        if (stations && stations.ocpTts) {
          dispatch(updateHandlingStations(stations.ocpTts))
        } else {
          dispatch(handlingStationsError('fetchingHandlingStationsFailed'))
        }
      })
      .catch(() => {
        dispatch(handlingStationsError('fetchingHandlingStationsFailed'))
      })
  }

export const fetchCompositions =
  (dateTime: string, trainNumber: string, station: string, changeView?: ChangeViewFunction) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingCompositions())
    fetchCompositionsFromAPI(dateTime, trainNumber, station)
      .then((compositions) => {
        dispatch(updateCompositions(compositions))
        if (changeView) changeView()
      })
      .catch(() => {
        dispatch(compositionsFetchingError('fetchingCompositionsFailed'))
      })
  }

export const searchTowings =
  (vehicleType: string, vehicleNumber: string) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingTowingVehicle())
    fetchTowingVehiclesFromAPI(vehicleType, vehicleNumber)
      .then((towing) => {
        if (towing) {
          dispatch(updateTowingVehicle(towing))
        } else {
          dispatch(towingError('towingSearchError'))
        }
      })
      .catch((err) => {
        logError(err)
        dispatch(towingError('towingSearchError'))
      })
  }

export const refetchTowing = (vehicleType: string, vehicleNumber: string) => {
  return (dispatch: TypedDispatch, getState: () => AppState) => {
    dispatch(startFetchingTowingVehicle())
    fetchTowingVehiclesFromAPI(vehicleType, vehicleNumber)
      .then((towing) => {
        if (towing) {
          dispatch(refetchTowingVehicle(towing))
        } else {
          dispatch(clearTowingVehicle())
          dispatch(towingError('towingSearchError'))
        }
      })
      .catch((err) => {
        logError(err)
        dispatch(clearTowingVehicle())
        dispatch(towingError('towingSearchError'))
      })
  }
}

export const confirmCompositions =
  (
    trainNumber: string,
    trainDate: string,
    queryDate: string,
    countryCode: string,
    locationCode: string,
    sourceSystem?: string,
    checkDateTime?: string,
    type?: string,
    locomotives?: Array<Locomotive> | null,
    secondAction?: string | null
  ) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startCheckOrInspection())
    postCompositionConfirmation(
      trainNumber,
      sourceSystem === 'KAPU' ? queryDate : trainDate,
      countryCode || 'FI',
      locationCode.toUpperCase(),
      checkDateTime,
      type,
      locomotives
    )
      .then((compositions) => {
        if (sourceSystem === 'RCS' && compositions.resultCode === '0000')
          dispatch(
            fetchCompositions(moment(queryDate).format('YYYY-MM-DD'), trainNumber, locationCode)
          )
        else dispatch(updateCompositions(compositions))
      })
      .catch(() => dispatch(compositionsFetchingError('fetchingCompositionsFailed')))
    if (secondAction) {
      postCompositionConfirmation(
        trainNumber,
        sourceSystem === 'KAPU' ? queryDate : trainDate,
        countryCode || 'FI',
        locationCode.toUpperCase(),
        secondAction,
        checkDateTime,
        locomotives
      )
        .then((compositions) => {
          if (sourceSystem === 'RCS' && compositions.resultCode === '0000')
            dispatch(
              fetchCompositions(moment(queryDate).format('YYYY-MM-DD'), trainNumber, locationCode)
            )
          else dispatch(updateCompositions(compositions))
        })
        .catch(() => dispatch(compositionsFetchingError('fetchingCompositionsFailed')))
    }
  }

export const getCompositionSearchHistory =
  () =>
  (dispatch: TypedDispatch): void => {
    getCompositionSearchHistoryFromDB().then((history) => {
      dispatch(updateCompositionHistory(history))
    })
  }

export const compositionFromHistory =
  (composition: Compositions, changeView: ChangeViewFunction) =>
  (dispatch: TypedDispatch): void => {
    dispatch(setCompositionFromHistory(composition))
    changeView()
  }

export const loadCompositionFromHistory =
  (date: string, station: string, trainNumber: string) =>
  (dispatch: TypedDispatch): void => {
    searchedCompositionFromDB(date, trainNumber, station)
      .then((compositions) => {
        if (compositions.length > 0) {
          return dispatch(updateCompositions(compositions[0]))
        }
        throw Error('No cached composition')
      })
      .catch(() => {
        dispatch(compositionsFetchingError('fetchingCompositionsFailed'))
      })
  }

export const deleteCompositionFromHistory =
  (params: SearchDeleteParams) =>
  (dispatch: TypedDispatch): void => {
    if (params.trainNumber && params.station && params.date)
      deleteCompositionFromDB(params.trainNumber, params.station, params.date).then(() =>
        dispatch(getCompositionSearchHistory())
      )
  }

export const fetchTimetable =
  (isUsedForDriving: boolean, parts: Array<TimetableParams>) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingTimetable())
    fetchTimetableFromAPI(isUsedForDriving, parts)
      .then((timetable) => {
        const part = parts[0] || {
          depStation: '',
          arrStation: '',
          trainNumber: '',
          timetableDate: '',
        }
        const stations = `${part.depStation ? ` ${part.depStation}-` : ''}${part.arrStation || ''}`
        const partInfo = `${part.trainNumber || ''}${stations} ${part.timetableDate || ''}`
        const fileName = `Aikataulu ${isUsedForDriving ? 'ajamiseen ' : ''}${partInfo}.pdf`
        const blob = new Blob([timetable], { type: 'application/pdf' })
        dispatch(updateTimetable())
        FileSaver.saveAs(blob, fileName)
      })
      .catch((err) => {
        const errorInput = getApiErrorInput(err)
        if (errorInput && errorInput.code === 'FORBIDDEN' && isUsedForDriving) {
          dispatch(timetableError('timetableForDrivingForbiddenError'))
        } else {
          dispatch(timetableError('timetableError'))
        }
      })
  }

export const fetchCalendarURL =
  () =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingCalendar())
    fetchCalendarURLFromAPI()
      .then((calendarUrl) => {
        dispatch(updateCalendar(calendarUrl))
      })
      .catch(() => dispatch(calendarError('calendarError')))
  }

export const fetchTrainPunctuality =
  (trains: TrainParams[]) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingTrainPunctuality())
    fetchTrainPunctualityFromAPI(trains)
      .then((punctuality) => dispatch(updatePunctuality(punctuality)))
      .catch((err) => {
        dispatch(punctualityError(getErrorString(err, 'punctuality')))
      })
  }

export const parseTrainsAndFetchPunctuality = (shift: Shift, dispatch: TypedDispatch): Shift => {
  const tasks = shift.tasks || []

  const taskTrains = tasks
    .filter((t) => Boolean(t.trainNumberNumeric) && t.trainNumber !== '')
    .map((t) => ({
      trainNumber: t.trainNumberNumeric,
      trainDate: selectOperatingDateForTask(t),
    }))

  if (taskTrains.length > 0) {
    const uniqueTrains: TrainParams[] = []
    taskTrains
      .filter((t) => t.trainDate <= moment().format('YYYY-MM-DD')) // filter out future trains
      .forEach((e) => {
        if (
          !uniqueTrains.find((u) => u.trainNumber === e.trainNumber && u.trainDate === e.trainDate)
        )
          uniqueTrains.push(e)
      })

    if (uniqueTrains.length > 0) dispatch(fetchTrainPunctuality(uniqueTrains))
  }

  return shift
}

/**
 * Search punctuality info and call callback on success
 *
 * @param date Train running date
 * @param trainNumber Train number
 * @param changeCallback success callback
 */

export const searchPunctuality =
  (date: string, trainNumber: string, changeCallback: ChangeCallbackFunction | null) =>
  (dispatch: TypedDispatch): void => {
    dispatch(startFetchingTrainPunctuality())
    fetchTrainPunctualityFromAPI([{ trainDate: date, trainNumber }])
      .then((punctuality) => {
        if (punctuality && punctuality.length > 0 && punctuality.some((p) => Boolean(p))) {
          dispatch(updatePunctuality(punctuality))
          if (changeCallback) changeCallback()
        } else {
          throw new Error('Error loading punctuality')
        }
      })
      .catch((err) => {
        dispatch(punctualityError(getErrorString(err, 'punctuality')))
      })
  }

export const sendVersionInfo = async () => {
  const pushStatus = await getPushStatus()

  const log = {
    version: config.version,
    pushStatus: pushStatus,
  }

  postVersionInfo(log).catch(logError)
}

const getPushStatus = async () => {
  if (navigator.serviceWorker) {
    const registration = await navigator.serviceWorker.ready
    if (!registration.pushManager) {
      return 'noPushManager'
    } else {
      return await registration.pushManager.permissionState({
        userVisibleOnly: true,
        applicationServerKey: b64(config.vapid),
      })
    }
  }
}

export const fetchReasonCodesFromAPI = () => (dispatch: TypedDispatch) => {
  dispatch(startFetchingCauses())
  fetchReasonCodes()
    .then((causes) => dispatch(updateCauses(causes)))
    .catch((e) => {
      logError(e)
      dispatch(causesFetchingError(''))
    })
}

export const sendDeviationAmendment = (data: AmendmentData) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startSendingAmendment())
    sendDeviationAmendmentToAPI(data)
      .then(() => dispatch(updateAmendment()))
      .catch((err) => {
        dispatch(sendAmendmentError(getErrorString(err, 'amend')))
      })
  }
}

export const fetchCrewNotices = () => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingAllCrewNotices())
    fetchCrewNoticesFromAPI()
      .then((res) => dispatch(batchUpdateCrewNotice(res as CrewNotice[])))
      .catch((err) => dispatch(crewNoticeErrorAll(getErrorString(err, 'crewNotice'))))
  }
}

export const fetchCrewNotice = (crewNoticeId: string) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(startFetchingCrewNotice(crewNoticeId))
    fetchCrewNoticeFromAPI(crewNoticeId)
      .then((res) => dispatch(updateCrewNotice(res as CrewNotice)))
      .catch((err) => dispatch(crewNoticeError(crewNoticeId, err)))
  }
}

export const sendCrewNoticeAck = (crewNoticeId: string, ack: CrewNoticeAck, eventAt: Moment) => {
  const eventString = eventAt.toJSON()
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingCrewNotice(crewNoticeId))
    sendCrewNoticeAckToAPI({ crewNoticeId, ack, eventAt: eventString })
      .then(() => dispatch(updateCrewNoticeAck(crewNoticeId, ack, eventString)))
      .then(() => dispatch(fetchCrewNotice(crewNoticeId)))
      .catch((err) => {
        dispatch(crewNoticeError(crewNoticeId, getErrorString(err, 'crewNotice')))
      })
  }
}

export const fetchShiftNotices = () => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingShiftNotices())
    fetchShiftNoticesFromAPI()
      .then((res) => dispatch(updateShiftNotices(res)))
      .catch((err) => dispatch(shiftNoticeError(getErrorString(err, 'shiftNotice'))))
  }
}

export const sendCustomerFeedback = (feedback: string, messageDateTime: Timestamp) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startSendingCustomerFeedback())
    sendCustomerFeedbackToAPI({ feedback, messageDateTime })
      .then(() => dispatch(updateCustomerFeedback('')))
      .catch((err) => {
        dispatch(updateCustomerFeedback(getErrorString(err, 'general')))
      })
  }
}

export const sendObservationMessage = (observationMessage: ObservationMessage) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startSendingObservationMessage())
    sendObservationMessageToAPI(observationMessage)
      .then(() => saveObservationMessageToDB(observationMessage))
      .then(() => {
        return getObservationMessagesFromDB().then((res) =>
          dispatch(updateObservationMessages(res))
        )
      })
      .catch(() => {
        dispatch(setObservationMessageError('ObservationMessageError'))
      })
  }
}

export const fetchTowingForm = (vehicleType: string) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingTowingForm())
    return getTowingFormFromAPI(vehicleType) // TODO: decide when to use cached data and when not to
      .then((form) => {
        updateOrSaveTowingFormToDB(form)
        dispatch(updateTowingForm(form))
      })
      .catch((err) => {
        // TODO: create translations for error string
        dispatch(towingFormError(getErrorString(err, 'towingForm')))
      })
  }
}

export const fetchTowingFormById = (contentfulId: string) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingTowingForm())
    return getTowingFormByIdFromAPI(contentfulId)
      .then((form) => {
        updateOrSaveTowingFormToDB(form)
        dispatch(updateTowingForm(form))
      })
      .catch((err) => {
        const errorInput = getApiErrorInput(err)
        if (errorInput && errorInput.code === 'FORM_DELETED') {
          // TODO: test getErrorString formation in debugger
          dispatch(towingFormError('errors.towingFormContent.migrationError'))
        } else {
          getTowingFormByIdFromDB(contentfulId)
            .then((stateInDb) => dispatch(updateTowingForm(stateInDb)))
            .catch((dbError) => dispatch(towingFormError(getErrorString(err, 'towingForm'))))
        }
      })
  }
}

export const fetchTowingFormState = (
  vehicleType: string,
  vehicleId: string | null,
  userVehicleNumber: string | null,
  changeView: ChangeViewFunction | null
) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingTowingFormState())
    createOrGetTowingFormStateFromAPI(vehicleType, vehicleId, userVehicleNumber)
      .then((towingFormState) => {
        updateOrSaveTowingFormStateToDB(towingFormState)
        dispatch(updateTowingFormState(towingFormState))
        if (changeView) {
          changeView(towingFormState.id)
        }
        return towingFormState.contentfulId
      })
      .then((contentfulId) => {
        dispatch(fetchTowingFormById(contentfulId))
      })
      .catch((err) => {
        getTowingFormStateFromDB(vehicleType, vehicleId || userVehicleNumber)
          .then((stateInDb) => dispatch(updateTowingFormState(stateInDb)))
          .catch((dbError) => dispatch(towingFormError(getErrorString(err, 'towingFormState'))))
      })
  }
}

export const fetchTowingFormStateById = (id: string) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingTowingFormState())
    Promise.allSettled([getTowingFormStateByIdFromAPI(id), getTowingFormStateByIdFromDB(id)])
      .then((towingFormStates) => {
        const remoteState = towingFormStates[0]
        const localState = towingFormStates[1]
        const remoteSucceeded = remoteState.status === 'fulfilled'
        const localSucceeded = localState.status === 'fulfilled'
        if (remoteSucceeded) {
          let useAndResendLocalState = false
          if (localSucceeded) {
            const lastSavedTimestamp = Date.parse(localState.value?.lastSaved) / 1000
            const createdAtTimestamp = localState.value?.created_at
              ? parseInt(localState.value.created_at)
              : 0
            if (localState.value && lastSavedTimestamp < createdAtTimestamp) {
              if (remoteState.value.lastSaved === localState.value.lastSaved) {
                useAndResendLocalState = true
              } else {
                dispatch(towingFormStateError(getErrorString(Conflict, 'towingFormState'))) //TODO: Check this
              }
            }
          }

          if (useAndResendLocalState && localSucceeded) {
            dispatch(saveTowingFormState(localState.value))
            return localState.value.contentfulId
          } else {
            const lastSavedTimestamp = Date.parse(remoteState.value.lastSaved) / 1000
            const stateToSave = {
              ...remoteState.value,
              created_at: lastSavedTimestamp.toString(),
            }
            updateOrSaveTowingFormStateToDB(stateToSave)
            dispatch(updateTowingFormState(stateToSave))
            return stateToSave.contentfulId
          }
        } else {
          const errorInput = getApiErrorInput(remoteState.reason)
          if (errorInput && errorInput.code === 'FORM_DELETED') {
            throw remoteState.reason
          } else if (localSucceeded) {
            dispatch(updateTowingFormState(localState.value))
            return localState.value.contentfulId
          } else {
            throw new Error('Failed to fetch towing form state')
          }
        }
      })
      .then((contentfulId) => {
        dispatch(fetchTowingFormById(contentfulId))
      })
      .catch((err) => {
        const errorInput = getApiErrorInput(err)
        if (errorInput && errorInput.code === 'FORM_DELETED') {
          // TODO: test getErrorString formation in debugger
          dispatch(towingFormError('errors.towingFormContent.migrationError'))
        } else {
          dispatch(towingFormStateError(getErrorString(err, 'towingFormState')))
        }
      })
  }
}

export const saveTowingSteps = (
  stepsOrSelectionsByKey: TowingFormStepOrSelection,
  stepKey?: string
) => {
  return (dispatch: TypedDispatch, getState: () => AppState) => {
    const { towingFormState } = getState()
    const message: ExtendedTowingAuditMessage = {
      action: `Towing step: ${JSON.stringify(stepsOrSelectionsByKey)}`,
      formId: towingFormState.id,
      changeDateTime: moment().utc().format(),
      vehicleType: towingFormState.vehicleType,
      vehicleNumber: towingFormState.vehicleNumber,
      id: uuidv4(),
    }
    sendTowingAuditMessage(message)
    const provisionalTowingFormState = {
      ...towingFormState,
      actionsAndSelectionsByKey: {
        ...towingFormState.actionsAndSelectionsByKey,
        ...stepsOrSelectionsByKey,
      },
    }
    dispatch(startSavingTowingFormState(provisionalTowingFormState))
    if (stepKey) {
      dispatch(startSavingTowingStep(stepKey))
    }
    const newFormState = {
      ...towingFormState,
      loading: undefined,
      saving: undefined,
      error: undefined,
      savingStep: undefined,
      actionsAndSelectionsByKey: Object.assign(
        towingFormState.actionsAndSelectionsByKey,
        stepsOrSelectionsByKey
      ),
    }
    saveTowingFormStateToAPI(newFormState)
      .then((formStateResponse) => {
        const lastSavedTimestamp = Date.parse(formStateResponse.lastSaved) / 1000
        const responseStateWithCreatedAt = {
          ...formStateResponse,
          created_at: lastSavedTimestamp.toString(),
        }
        dispatch(updateTowingFormState(responseStateWithCreatedAt))
        updateOrSaveTowingFormStateToDB(responseStateWithCreatedAt)
      })
      .catch((err) => {
        if (!isConflictError(err)) {
          // TODO: test getErrorString formation in debugger
          const newFormStateWithCreatedAt = {
            ...newFormState,
            created_at: unixTimestamp().toString(),
          }
          updateOrSaveTowingFormStateToDB(newFormStateWithCreatedAt)
          dispatch(updateTowingFormState(newFormStateWithCreatedAt))
        }
        dispatch(towingFormStateError(getErrorString(err, 'towingFormState')))
      })
  }
}
// TODO: recognize and handle a situation where there are existing changes stored locally but not saved to backend
export const saveTowingFormState = (
  towingFormState: TowingFormState,
  action?: string,
  stepKey?: string
) => {
  return (dispatch: TypedDispatch) => {
    const message: ExtendedTowingAuditMessage = {
      action: `Towing action: ${action || 'no action'}`,
      formId: towingFormState.id,
      changeDateTime: moment().utc().format(),
      vehicleType: towingFormState.vehicleType,
      vehicleNumber: towingFormState.vehicleNumber,
      id: uuidv4(),
    }
    sendTowingAuditMessage(message)
    const newFormState = {
      ...towingFormState,
      loading: undefined,
      saving: undefined,
      error: undefined,
      savingStep: undefined,
    }
    dispatch(startSavingTowingFormState(newFormState)) // update ui state optimistically
    if (stepKey) dispatch(startSavingTowingStep(stepKey))
    saveTowingFormStateToAPI(newFormState, action)
      .then((formStateResponse) => {
        // TODO: check for conflict(s)
        const lastSavedTimestamp = Date.parse(formStateResponse.lastSaved) / 1000
        const responseStateWithCreatedAt = {
          ...formStateResponse,
          created_at: lastSavedTimestamp.toString(),
        }
        dispatch(updateTowingFormState(responseStateWithCreatedAt))
        updateOrSaveTowingFormStateToDB(responseStateWithCreatedAt)
      })
      .catch((err) => {
        const errorInput = getApiErrorInput(err)
        if (
          errorInput &&
          errorInput.code === 'BAD_REQUEST' &&
          errorInput.message === 'Towing form validation failed'
        ) {
          dispatch(towingFormStateError('errors.towingFormState.validationError'))
        } else if (errorInput && errorInput.code === 'FORM_DELETED') {
          // TODO: test getErrorString formation in debugger
          dispatch(towingFormError('errors.towingFormContent.migrationError'))
        } else {
          if (!isConflictError(err)) {
            const newFormStateWithCreatedAt = {
              ...towingFormState,
              created_at: unixTimestamp().toString(),
            }
            updateOrSaveTowingFormStateToDB(newFormStateWithCreatedAt)
            dispatch(updateTowingFormState(newFormStateWithCreatedAt))
          }
          dispatch(towingFormStateError(getErrorString(err, 'towingFormState')))
        }
      })
  }
}

export const fetchMultipleTowings = (vehicleCompounds: Array<string>) => {
  return (dispatch: TypedDispatch): void => {
    dispatch(startFetchingTowingVehicle())
    fetchMultipleTowingVehiclesFromAPI(vehicleCompounds)
      .then((towings) => {
        dispatch(updateTowingVehicles(towings))
      })
      .catch((err) => {
        dispatch(towingError(getErrorString(err, 'towingVehicle')))
      })
  }
}

export const fetchTowingPatterns = () => {
  return (dispatch: TypedDispatch) => {
    dispatch(startFetchingVehiclePatterns())
    getTowingVehiclePatternsFromAPI()
      .then((patterns) => {
        dispatch(updateVehiclePatterns(patterns))
      })
      .catch((err) => {
        dispatch(setVehiclePatternsError(getErrorString(err, 'towingVehiclePattern')))
      })
  }
}

export const fetchAssemblies = (params: TaskParams) => {
  return (dispatch: TypedDispatch) => {
    dispatch(startLoadingAssemblies())
    getAssembliesFromAPI(params)
      .then((assemblies) => {
        dispatch(updateAssemblies(assemblies))
      })
      .catch(() => {
        dispatch(assemblyLoadError('errors.assemblies.notFound'))
      })
  }
}
