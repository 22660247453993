import { css } from '@mui/material'
import { styled } from '@mui/material/styles'

import { theme as oldTheme } from '../../Theme'
import Link from '../Link'

interface HeaderProps {
  loading?: boolean
}

export const HeaderAction = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading',
})<HeaderProps>(({ theme, loading }) => ({
  ...theme.spacing['small'],
  lineHeight: 0.5,
  color: theme.palette.common.kenttaBlue,
  cursor: 'pointer',
  animation: loading
    ? css`
        ${oldTheme.animations.spin} 1s linear infinite
      `
    : 'none',
  animationDirection: 'reverse',
  transformOrigin: 'center center',
}))

interface HeaderLinkProps {
  disabled?: boolean
  loading?: boolean
}

const HeaderLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'loading',
})<HeaderLinkProps>(({ theme, disabled, loading }) => ({
  ...theme.spacing['small'],
  lineHeight: 0.5,
  color: disabled ? theme.palette.grey[600] : theme.palette.common.kenttaBlue,
  animation: loading
    ? css`
        ${oldTheme.animations.spin} 1s linear infinite
      `
    : 'none',
  animationDirection: 'reverse',
  transformOrigin: 'center center',
}))

export default HeaderLink
