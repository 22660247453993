import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import config from '../../config'
import { apiDELETE, apiGET, apiPOST } from '../../lib/api'
import b64 from '../../lib/b64'
import { getDeviceIdFromDb, saveDeviceIdToDB } from '../../lib/data'
import { Device, PushStatus, SerializedPushSubscription, TestPushMessage } from '../../types/Input'
import { serializePushSubscription } from './utils'

const fetchDevices = (): Promise<Device[]> => {
  return apiGET('devices')
}

export const fetchDevicesAction = createAsyncThunk<Device[], void, { rejectValue: string }>(
  'deviceManagement/fetchDevices',
  async (_, { rejectWithValue }) => {
    try {
      return fetchDevices()
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch devices')
    }
  }
)

const handleSubscribe = async (): Promise<{
  subscription: SerializedPushSubscription
  pushStatus: PushStatus
}> => {
  try {
    if (!navigator.serviceWorker) {
      return { subscription: null, pushStatus: 'not_possible' }
    }

    const registration = await navigator.serviceWorker.ready
    if (!registration.pushManager) {
      return { subscription: null, pushStatus: 'not_possible' }
    }

    let permission = Notification.permission

    if (permission === 'default') {
      permission = await Notification.requestPermission()
    }

    if (permission !== 'granted') {
      return { subscription: null, pushStatus: 'not_allowed_in_browser' }
    }

    let subscription = await registration.pushManager.getSubscription()

    if (subscription === null) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: b64(config.vapid),
      })
    }

    const serializedSubscription = await serializePushSubscription(subscription)
    return { subscription: serializedSubscription, pushStatus: 'permission_granted' }
  } catch (err) {
    throw new Error('Failed to subscribe to device.')
  }
}

type UpsertDevicePayloadType = Omit<Device, 'lastLogin' | 'deviceName' | 'testMessages'>

const upsertDevice = (device: UpsertDevicePayloadType): Promise<Device> => {
  return apiPOST('devices', device)
}

export const upsertDeviceAction = createAsyncThunk<Device, void, { rejectValue: string }>(
  'deviceManagement/upsertDevice',
  async (payload, { rejectWithValue }) => {
    try {
      let deviceId = await getDeviceIdFromDb()
      let subscription: SerializedPushSubscription = null
      let pushStatus: PushStatus = null
      if (!deviceId) {
        deviceId = uuidv4()
        const { subscription: sub, pushStatus: status } = await handleSubscribe()
        subscription = sub
        pushStatus = status
        await saveDeviceIdToDB(deviceId)
      }

      return await upsertDevice({ deviceId, subscription, pushStatus })
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to upsert device')
    }
  }
)

export const subscribePushNotificationsAction = createAsyncThunk<
  Device,
  string,
  { rejectValue: string }
>('deviceManagement/subscribePushNotifications', async (deviceId, { rejectWithValue }) => {
  try {
    const { subscription, pushStatus } = await handleSubscribe()
    return await upsertDevice({ deviceId, subscription, pushStatus })
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to subscribe push notifications')
  }
})

const sendUnsubscription = (device: Device): Promise<Device> => {
  return apiPOST('push/unregister', { device })
}

export const unsubscribePushNotificationsAction = createAsyncThunk<
  Device,
  Device,
  { rejectValue: string }
>('deviceManagement/unsubscribePushNotifications', async (device, { rejectWithValue }) => {
  try {
    if (navigator.serviceWorker) {
      const registration = await navigator.serviceWorker.ready
      const subscription = await registration.pushManager.getSubscription()
      if (!subscription) {
        throw new Error('No subscription found')
      }

      const unsubscribedDevice = { ...device, subscription: null }
      await subscription.unsubscribe()
      return await sendUnsubscription(unsubscribedDevice)
    } else {
      throw new Error('No serviceWorker found')
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to unsubscribe push notifications')
  }
})

const sendTestPushToDevice = (
  payload: DeviceTestMessagePayload
): Promise<DeviceTestMessagePayload> => {
  return apiPOST('devices/test/push', payload)
}

export type DeviceTestMessagePayload = {
  deviceId: string
  testPushMessage: TestPushMessage
}

export const sendTestPushMessageAction = createAsyncThunk<
  DeviceTestMessagePayload,
  DeviceTestMessagePayload,
  { rejectValue: string }
>('deviceManagement/sendTestPushMessage', async (payload, { rejectWithValue }) => {
  try {
    return await sendTestPushToDevice(payload)
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to send test push message')
  }
})

const deleteDevice = (deviceId?: string): Promise<unknown> => apiDELETE(`devices/${deviceId}`)

export const deleteDeviceAction = createAsyncThunk<
  string, // deviceId
  string, // deviceId
  { rejectValue: string }
>('deviceManagement/deleteDevice', async (deviceId, { rejectWithValue }) => {
  try {
    await deleteDevice(deviceId)
    return deviceId
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to delete device')
  }
})

const updateDeviceName = (deviceId: string, newName: string): Promise<Device> => {
  return apiPOST(`/devices/${deviceId}`, { newName })
}

type UpdateDeviceNamePayload = {
  deviceId: string
  newName: string
}

export const updateDeviceNameAction = createAsyncThunk<
  Device,
  UpdateDeviceNamePayload,
  { rejectValue: string }
>('deviceManagement/updateDeviceName', async (payload, { rejectWithValue }) => {
  try {
    return await updateDeviceName(payload.deviceId, payload.newName)
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to update device name')
  }
})
