import { FoundState } from 'found'

import { DefectState } from '../reducers/createDefectSlice'
import { CommonDataState } from '../reducers/defectCommonDataSlice'
import { DefectsState } from '../reducers/defectsSlice'
import { IncidentsState } from '../reducers/incidentsSlice'
import { ActionsState } from '../reducers/serviceOrders/actionsSlice'
import { ServiceOrdersState } from '../reducers/serviceOrders/serviceOrdersSlice'
import { SmDisruptionState } from '../reducers/smDisruptionSlice'
import { TrainEquipmentInfoState } from '../reducers/trainEquipmentInfoSlice'
import { Timestamp } from './Common'
import {
  Assembly,
  Causes,
  CheckedModal,
  Compositions,
  Contact,
  CrewNotice,
  EnergyEfficiency,
  Feedback,
  Finding,
  HandlingStation,
  Modal,
  News,
  ObservationMessage,
  Personnel,
  PhoneContactGroup,
  RestDay,
  RollingGuideData,
  SearchedShift,
  Shift,
  ShiftNotice,
  SignInStatus,
  TimetableParams,
  TowingFormContent,
  TowingFormStateChange,
  TowingVehicle,
  TowingVehiclePattern,
  TrainPunctuality,
  User,
} from './Input'

export enum NetworkStatus {
  ON = 'ON',
  OFF = 'OFF',
}

export type SystemState = Readonly<{
  adminBarCollapsed: boolean
  nightMode: boolean
  shortNumbers: boolean
  network: NetworkStatus
  now: number
  newVersion: boolean
  screenWidth: number
  virtualTime: {
    enabledAt: number
    offset: number
    multiplier: number
  }
  redirectAfterLoad?: boolean
  taskRowDebugEnabled: boolean
  pushAlertDismissed: boolean
}>

// Page states
export type MainPageState = Readonly<{
  headerOpen: boolean
  dayIndex: number
}>

export type SchedulePageState = Readonly<{
  eventsOpen: boolean
  feedbackOpen: Record<string, boolean>
}>

export type TaskState = Readonly<{
  open: boolean
  crossed: boolean
  selected: boolean
  visibleContacts: Array<string>
}>

export type ShiftPageState = {
  readonly taskState: Record<string, Record<number, TaskState>>
}

export type PersonnelPageState = Readonly<{
  loading: boolean
  error: string
  personnelList: Personnel | null
  personnelHistory: Array<Personnel>
}>

// Model states
export type FeedbackState = Readonly<{
  workspace: Record<string, Feedback>
  byId: Record<string, Feedback>
  idsByShiftId: Record<string, string>
  idsByListId: Record<string, string>
}>

export type ShiftState = Readonly<{
  loading: boolean
  error: string
  byId: Record<string, Shift>
  restDays: Array<RestDay>
  scheduleFetchTime?: Date | null
}>

export type ShiftDetailsState = Readonly<{
  loading: boolean
  error: string
  byId: Record<string, Shift>
}>

export type SearchedShiftsState = Readonly<{
  loading: boolean
  error: string
  list: SearchedShift
  selected: Shift
  history: Array<Shift>
}>

export type FindingEntry = Readonly<{
  timestamp: number
  findings: Array<Finding>
}>

export type FindingsState = Readonly<{
  equipmentLoading?: Array<string>
  equipmentErrors?: Record<string, string>
  findingsByLocId?: Record<string, FindingEntry>
  equipmentWithError?: Array<string>
}>

export type SignInStatusState = {
  readonly byId: Record<string, SignInStatus>
}

export type ResponseReadState = {
  readonly byId: Record<string, boolean>
}

export type UserState = Readonly<{
  created_at: number
  token: string
  defectsPageAllowed?: boolean
  [key: string]: any
}> &
  User

export type PhoneContactsState = Readonly<{
  loading: boolean
  error: string
  phoneContacts?: Array<PhoneContactGroup>
}>

export type CompositionsState = Readonly<{
  loading: boolean
  error: string
  compositionForTrain?: Compositions
  posting: boolean
  history: Array<Compositions>
  modal: Modal
}>

export type TimetableState = Readonly<{
  loading: boolean
  error: string
  modal: CheckedModal
  usedTimetableParams: Array<TimetableParams>
}>

export type CalendarState = Readonly<{
  loading: boolean
  error: string
  url: string
}>

export type PunctualityState = Readonly<{
  loading: boolean
  error: string
  punctualityByTrain: Record<string, TrainPunctuality>
  selectedTrainDate: string
  selectedTrainNumber: string
}>

export type DateTrainState = Readonly<{
  date: string
  dateActive?: boolean
  trainNumber?: string
  trainNumberActive?: boolean
}>

export type PunctualityPersonnelState = DateTrainState

export type TimeTableSearchState = DateTrainState & {
  readonly isUsedForDriving: boolean
}

export type ShiftSearchState = DateTrainState &
  Readonly<{
    shiftId: string
    shiftIdActive?: boolean
    startStation: string
    startStationActive?: boolean
    user: User
  }>

export type CausesState = Readonly<{
  loading: boolean
  error: boolean
  causes: Causes
}>

export type AmendmentType = 'AMEND' | 'CHALLENGE'
export type TrainRunningTransactionType = 'T' | 'L'

export type ReasonCode = {
  groupCode?: string | null
  level1Code?: string | null
  level2Code?: string | null
}

export type AmendmentDataInput = {
  trainNumber: number
  departureDate: string
  stationShortCode: string
  previousStationCode?: string
  nextStationCode?: string
  trainRunningTransactionType: TrainRunningTransactionType
  originalReasonCode: ReasonCode
  delayMinutes: number
  trainCategory: string
  trainType: string
  type: AmendmentType
}

export type AmendmentData = {
  type: AmendmentType
  relatedTrainNumber: number
  departureDate: string
  stationShortCode: string
  previousStationCode?: string
  nextStationCode?: string
  trainRunningTransactionType: TrainRunningTransactionType
  originalReasonCode: ReasonCode
  amendedReasonCode: ReasonCode | null
  description: string | null
}

export enum SendStatus {
  READY = 'ready',
  SENDING = 'sending',
  SENT = 'sent',
}

//TODO: Add amendments to state and get rid of hooks
export type AmendmentState = Readonly<{
  sendStatus: SendStatus
  error: string
  selectedGroup?: string | null
  selectedFirstLevelCause?: string | null
  selectedSecondLevelCause?: string | null
}>

export type CrewNoticeState = {
  readonly byCrewNoticeId: Record<string, CrewNotice>
}

export type ShiftNoticeState = Readonly<{
  loading: boolean
  error: string
  notices: Array<ShiftNotice>
}>

export type ContactState = {
  readonly byId: Record<string, Contact>
}

export type CustomerFeedbackState = Readonly<{
  loading: boolean
  error: string
}>

export type ObservationMessagesState = Readonly<{
  observationMessages: Array<ObservationMessage>
  loading: boolean
  error: string
}>

export type HandlingStationsState = Readonly<{
  loading: boolean
  error: string
  stations: Array<HandlingStation>
}>

export type TowingPatternState = Readonly<{
  loading: boolean
  error: string
  towingPatterns: Array<TowingVehiclePattern>
}>

export type TowingFormReduxState = Readonly<{
  loading: boolean
  error: string
  id: string
  vehicleType: string
  vehicleNumber: string
  actionsAndSelectionsByKey: Record<string, string>
  contentfulId: string
  stateChanges: Array<TowingFormStateChange>
  lastSaved: Timestamp
  saving: boolean
  savingStep: string
}>

export type TowingLastSearched = {
  formName: string
  vehicleNumber: string
  vehicleType: string
}

export type TowingVehicleSearchState = {
  lastSearched: TowingLastSearched
  readonly loading: boolean
  readonly error: string
  readonly towingVehiclesByCompound: Record<string, TowingVehicle>
}

export type TowingFormContentState = Readonly<{
  loading: boolean
  error: string
  stepsById: Record<string, unknown>
  towingForm: TowingFormContent | object
}>

export type AssemblyState = Readonly<{
  loading: boolean
  error: string
  byId: Record<string, Assembly>
}>

export type EnergyEfficiencyState = Readonly<{
  loading: boolean
  error: string
  energyEfficiency?: EnergyEfficiency
}>

export type NewsState = Readonly<{
  loading: boolean
  error: string
  news: Array<News>
}>

export type RollingGuidesState = Readonly<{
  loading: boolean
  error: string
  rollingGuides: RollingGuideData
}>

// Full state tree
export interface AppState {
  readonly found: FoundState
  readonly system: SystemState
  readonly shifts: ShiftState
  readonly shiftDetails: ShiftDetailsState
  readonly signInStatuses: SignInStatusState
  readonly responseReads: ResponseReadState
  readonly feedbacks: FeedbackState
  readonly user: UserState
  readonly mainPage: MainPageState
  readonly schedulePage: SchedulePageState
  readonly shiftPage: ShiftPageState
  readonly personnel: PersonnelPageState
  readonly searchedShifts: SearchedShiftsState
  readonly findings: FindingsState
  readonly compositions: CompositionsState
  readonly timetable: TimetableState
  readonly punctuality: PunctualityState
  readonly phoneContacts: PhoneContactsState
  readonly causes: CausesState
  readonly amendment: AmendmentState
  readonly calendar: CalendarState
  readonly crewNotices: CrewNoticeState
  readonly contacts: ContactState
  readonly customerFeedback: CustomerFeedbackState
  readonly observationMessages: ObservationMessagesState
  readonly handlingStations: HandlingStationsState
  readonly towingPatterns: TowingPatternState
  readonly towingFormState: TowingFormReduxState
  readonly towingSearch: TowingVehicleSearchState
  readonly towingForm: TowingFormContentState
  readonly assemblies: AssemblyState
  readonly energyEfficiency: EnergyEfficiencyState
  readonly rollingGuides: RollingGuidesState
  readonly shiftNotices: ShiftNoticeState
  readonly news: NewsState
  readonly defectCommonData: CommonDataState
  readonly defects: DefectsState
  readonly defect: Omit<DefectState, 'equipments'>
  readonly serviceOrders: ServiceOrdersState
  readonly serviceOrderActions: ActionsState
  readonly incidents: IncidentsState
  readonly smDisruptions: SmDisruptionState
  readonly trainEquipmentInfo: TrainEquipmentInfoState
}
