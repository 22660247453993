import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { actAsUser } from '../../actions/api'
import { toggleAdminBar } from '../../actions/system'
import { AppState, Noop, Session } from '../../types'
import ActAs from './ActAs'

const Bar = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.common.kenttaBlue,
}))

const Constrain = styled('div')(({ theme }) => ({
  padding: '10px',
  margin: '0 auto',
  [theme.breakpoints.up('tablet')]: {
    maxWidth: '500px',
  },
}))

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Button = styled('button')(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  cursor: 'pointer',
  background: theme.palette.common.kenttaBlue,
  border: 'none',
}))

const TopPadding = styled('div')({
  paddingTop: '20px',
})

type Props = {
  actingAs?: boolean
  actingAsCommuter?: boolean
  actingAsMaintenance?: boolean
  collapsed?: boolean
  updating?: boolean
  isCommuterManager: boolean
  actingAsServiceDriver?: boolean
  number?: string
  t: TFunction
  onActAs: (
    number: string,
    isCommuter: boolean,
    isMaintenance: boolean,
    isServiceDriver: boolean,
    session: Session
  ) => void
  onToggle: Noop
}

const getActingAsString = (
  t: TFunction,
  isCommuterManager: boolean,
  actingAsCommuter?: boolean,
  actingAsMaintenance?: boolean,
  actingAsServiceDriver?: boolean
) => {
  if (actingAsCommuter || isCommuterManager) {
    return t('asCommuter')
  } else if (actingAsMaintenance) {
    return t('asMaintenance')
  } else if (actingAsServiceDriver) {
    return t('asServiceDriver')
  }
  return t('asNonCommuter')
}

const AdminBar = ({
  t,
  actingAs,
  collapsed,
  updating,
  number,
  actingAsCommuter,
  actingAsMaintenance,
  isCommuterManager,
  actingAsServiceDriver,
  onActAs,
  onToggle,
}: Props) => (
  <Bar>
    <Constrain>
      {updating ? (
        <Typography variant="adminBarBase">{t('loadingUserInformation')}</Typography>
      ) : (
        <Row>
          <Typography variant="adminBarBase">
            {actingAs ? t('actingAs', { number }) : t('showingOwnInformation')}
            {actingAs &&
              ` ${getActingAsString(
                t,
                isCommuterManager,
                actingAsCommuter,
                actingAsMaintenance,
                actingAsServiceDriver
              )}`}
          </Typography>
          <Button onClick={onToggle}>
            <Typography variant="adminBarSmall">{t('change')}</Typography>
          </Button>
        </Row>
      )}
      {collapsed || updating ? undefined : (
        <TopPadding>
          <ActAs isCommuterManager={isCommuterManager} onSubmit={onActAs} />
        </TopPadding>
      )}
    </Constrain>
  </Bar>
)

const mapStateToProps = (state: AppState) => {
  return {
    number: state.user.number,
    actingAs:
      state.user.number.replace(/^[0]*/, '') !== state.user.originalNumber.replace(/^[0]*/, ''),
    collapsed: state.system.adminBarCollapsed,
    updating: state.user.loading,
    actingAsCommuter: state.user.actAsCommuter,
    actingAsMaintenance: state.user.actAsMaintenance,
    isCommuterManager: !!state.user.commuter_manager,
    actingAsServiceDriver: state.user.actAsServiceDriver,
  }
}

const mapDispatchToProps = {
  onActAs: actAsUser,
  onToggle: toggleAdminBar,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminBar))
