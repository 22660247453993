import { Alert } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import { useTypedSelector } from '..'
import Link from '../components/Link'
import { Container, Content } from '../components/page/PageComponents'
import { Device } from '../types/Input'

const AlertContainer = styled('div')(({ theme }) => ({
  ...theme.layout.fluidWidth(theme, 'small'),
  marginTop: theme.spacing('smaller'),
}))

const getPushAlert = (
  devices: Device[],
  currentDevice: Device
): { key: string; translationKey: string } | null => {
  if (!currentDevice) {
    return null
  }

  if (!('serviceWorker' in navigator) || !('pushManager' in ServiceWorkerRegistration.prototype)) {
    return {
      key: 'notSupported',
      translationKey: 'pushMessageAlert.notSupported',
    }
  }

  if (Notification.permission === 'denied') {
    return {
      key: 'denied',
      translationKey: 'pushMessageAlert.missingPermission',
    }
  }

  const isCurrentSubscribed = Boolean(currentDevice.subscription)
  const subscribedDevices = devices.filter((d) => d.subscription)
  const otherSubscribedDevicesCount = subscribedDevices.length - (isCurrentSubscribed ? 1 : 0)

  if (isCurrentSubscribed) {
    if (subscribedDevices.length > 1) {
      return {
        key: 'multipleDevices',
        translationKey: 'pushMessageAlert.multipleDevices',
      }
    }
    return null
  } else {
    if (otherSubscribedDevicesCount > 0) {
      return {
        key: 'pushBlocked',
        translationKey: 'pushMessageAlert.pushBlocked',
      }
    }

    return null
  }
}

const PushAlertContainer = () => {
  const [dismissedAlertKey, setDismissedAlertKey] = useState<string>(
    localStorage.getItem('lastDismissedAlertKey') || ''
  )

  const handleDismissAlert = useCallback((alertKey: string) => {
    setDismissedAlertKey(alertKey)
    localStorage.setItem('lastDismissedAlertKey', alertKey)
  }, [])

  const devices = useTypedSelector((state) => state.deviceManagement.devices)
  const currentDeviceId = useTypedSelector((state) => state.deviceManagement.currentDeviceId)
  const currentDevice: Device = devices.find((d) => d.deviceId === currentDeviceId)

  const pushAlert = getPushAlert(devices, currentDevice)
  const showAlert = pushAlert && pushAlert.key !== dismissedAlertKey

  if (!showAlert) {
    return null
  }

  return (
    <Container>
      <Content marginBottom="10px">
        <AlertContainer>
          <Alert
            variant="outlined"
            severity="info"
            onClose={() => handleDismissAlert(pushAlert.key)}
          >
            <Trans
              i18nKey={pushAlert.translationKey}
              components={[<Link to="/deviceManagement" key="0" />]}
            />
          </Alert>
        </AlertContainer>
      </Content>
    </Container>
  )
}

export default PushAlertContainer
