import { Session, User } from '../types'

export const maintenanceUrlPatterns = [
  /\/login*/,
  /\/towing*/,
  /\/search\/towings/,
  /\/options*/,
  /\/about*/,
]

type UserRoles = Omit<
  User,
  'number' | 'originalNumber' | 'name' | 'familyName' | 'personnelGroup' | 'error' | 'loading'
>
type Role = keyof UserRoles

const adminAssumedRole = (user: User, assumedRole: Role) => {
  return (user.admin || user.read_admin) && user[assumedRole] && user.number !== user.originalNumber
}

export const isMaintenanceUser = (user: User) =>
  user.maintenance || adminAssumedRole(user, 'maintenance')

export const isWithinMaintenanceUrls = (pathName: string) =>
  maintenanceUrlPatterns.some((pattern) => pattern.test(pathName))

export type DriverType = 'commuter_driver' | 'driver'

export const checkUserForDriverRole = (user: User): DriverType | null => {
  const commuter =
    user.commuter_driver || (user.commuter_manager && user.number !== user.originalNumber)
  const driver = user.driver
  return commuter ? 'commuter_driver' : driver ? 'driver' : null
}

export const isESalliUser = (session: Session) => {
  return (
    session.conductor ||
    session.driver ||
    session.logistics_driver ||
    session.waiter ||
    session.maintenance ||
    session.other
  )
}

export const isCommuterUser = (session: Session | User) => {
  return session.commuter_driver || session.commuter_manager || session.commuter_conductor
}
