import CancelIcon from '@mui/icons-material/Cancel'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import React from 'react'

import ArrowDown from '../../icons/ArrowDown'
import ArrowUp from '../../icons/ArrowUp'
import Checked from '../../icons/Checked'
import { Device, PushStatus } from '../../types/Input'
import DeviceDetails from './DeviceDetails'

const CardWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
}))

const IconRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '38% 32% 20% 10%',
  textDecoration: 'none',
  padding: theme.spacing(1),
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
  borderBottom: 'none',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}))

const DeviceCell = styled('div')(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...theme.typography.body2,
  maxWidth: 'calc(100% - 10px)',
  fontSize: '1rem',
}))

const LastLoginCell = styled('div')(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...theme.typography.body2,
  fontSize: '1rem',
}))

const NotificationsCell = styled('div')(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...theme.typography.body2,
  fontSize: '1rem',
}))

const ActionsCell = styled('div')(({ theme }) => ({
  flex: 0.5,
  textAlign: 'right',
}))

const ArrowIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const getPushStatusIcon = (status: PushStatus): React.ReactNode => {
  if (status === 'permission_granted') {
    return <Checked />
  }
  return <CancelIcon style={{ color: 'red' }} />
}

type DeviceCardProps = {
  device: Device
  isExpanded: boolean
  onExpand: () => void
  isCurrentDevice: boolean
  canBlockMessages: boolean
}

const DeviceCard = ({
  device,
  isExpanded,
  onExpand,
  isCurrentDevice,
  canBlockMessages,
}: DeviceCardProps) => {
  return (
    <CardWrapper>
      <IconRow onClick={onExpand}>
        <DeviceCell>{device.deviceName}</DeviceCell>
        <LastLoginCell>{format(new Date(device.lastLogin), 'dd.MM.yyyy')}</LastLoginCell>
        <NotificationsCell>{getPushStatusIcon(device.pushStatus)}</NotificationsCell>
        <ActionsCell>
          <ArrowIcon>
            {isExpanded ? <ArrowUp iconSize="normal" /> : <ArrowDown iconSize="normal" />}
          </ArrowIcon>
        </ActionsCell>
      </IconRow>
      {isExpanded && (
        <DeviceDetails
          device={device}
          isCurrentDevice={isCurrentDevice}
          canBlockMessages={canBlockMessages}
        />
      )}
    </CardWrapper>
  )
}

export default DeviceCard
