import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import convert from 'color-convert'
import _ from 'lodash'
import { HTMLAttributes } from 'react'

export enum PaddingSize {
  SMALL = 'small',
  SMALLEST = 'smallest',
  TINY = 'tiny',
  SMALLER = 'smaller',
  NORMAL = 'normal',
  LARGE = 'large',
  LARGER = 'larger',
  HUGE = 'huge',
  MORE_HUGE = 'moreHuge',
  BIGGEST = 'biggest',
}

export enum FontSize {
  Small = 'small',
  Smallest = 'smallest',
  Smaller = 'smaller',
  Normal = 'normal',
  Large = 'large',
  Larger = 'larger',
  MediumSmall = 'mediumSmall',
  MediumLarge = 'mediumLarge',
  SecondLargest = 'secondLargest',
  Largest = 'largest',
}

interface PaddedProps extends HTMLAttributes<HTMLDivElement> {
  padding: PaddingSize
}

export type CSSUnit = 'px' | 'vh'

const unwrapUnit = (unit: CSSUnit) => {
  const unitRegex = new RegExp(`^([\\d.]+)${unit}$`)
  return (valueWithUnit: string): number =>
    parseFloat(('' + valueWithUnit).replace(unitRegex, '$1'))
}

const wrapUnit = (unit: CSSUnit) => {
  return (value: number): string => `${value}${unit}`
}

export const vh: (arg1: number) => string = wrapUnit('vh')
export const unVh: (arg1: string) => number = unwrapUnit('vh')
export const px: (arg1: number) => string = wrapUnit('px')
export const unPx: (arg1: string) => number = unwrapUnit('px')

export const LAYOUT_LEFT_EDGE = 17

export const colors = {
  // color name in zeplin
  transparent: 'transparent',
  white: '#ffffff',
  grayBackground: '#f9f9f9',
  grayLighter: '#fafbfa',
  grayLight: '#e7e7e7',
  grayBlue: '#dfe3e5',
  grayPink: '#cdcdcd', // pinkish-gray
  grayDark: '#7f7f7f', // warm-gray
  grayWarm: '#848484', // warm-gray-2
  grayDarker: '#6d6d6d',
  grayPale: '#e3eef5',
  grayDisabled: '#ccc',
  grayDisabled2: '#a0a0a0',
  black: '#34404a', // charcoal-gray
  nightHighlight: '#323232', //black-four
  nightSeparator: '#2b2b2b', //black-five
  nightGray: '#262626', // black
  nightBlack: '#161616', // black-two
  nightBackground: '#090909', //black-three
  blackAbsolute: '#000000',
  darkGreen: '#077f00',
  lightGreen: '#c1e0a7',
  mediumGreen: '#00a149',
  primaryGreen: '#57a50b', // lawn-green
  primaryGreenTinted: '#c1e0a1',
  primaryGreenDisabled: '#93a97e',
  secondaryGreen: '#88cc66', // light-moss-green
  shallowGreen: '#dbf3df',
  primaryBlue: '#2479B3', // bluish
  primaryBlueDisabled: '#2377b080',
  primaryBlueTinted: '#9ab5c7',
  dirtyBlue: '#b8ccd9', // cloudy-blue
  lightBlue: '#cee4f2', // light-blue-gray
  shallowBlue: '#e3eef5',
  primaryYellow: '#ffc859',
  primaryYellowTinted: '#ffe6b4',
  sunYellow: '#fdf256',
  yellowDark: '#7c6432',
  yellowDarkTinted: '#dccca9',
  yellowSpecial: '#c79d45',
  // illustration backgrounds
  morning: '#f4bcc5',
  day: '#a3c4e1',
  evening: '#7f6988',
  night: '#2f3d52',
  // star colors
  star1: '#f36a60',
  star2: '#f6865e',
  star3: '#faa25c',
  star4: '#fdbe59',
  red: '#f26161',
  redTinted: '#e8b4b4',
  shallowRed: '#F5ACAC',
  redNight: '#7d0000',
  //fault responsible party color codes
  VR: '#57a50b',
  Finrail: '#f703ab',
  Outside: '#fde9d9',
  Väylä: '#00b0f0',
  Traffic: '#ffff99',
  Kentta: '#ffffff',
} as const
export type Color = keyof typeof colors

const spacing = {
  smallest: px(4),
  tiny: px(6),
  smaller: px(8),
  small: px(10),
  normal: px(16),
  large: px(20),
  larger: px(30),
  huge: px(40),
  moreHuge: px(48),
  biggest: px(60),
} as const
export type SpacingSize = keyof typeof spacing

const fontSize = {
  smallest: px(9),
  smaller: px(11),
  small: px(13),
  mediumSmall: px(14),
  normal: px(15),
  mediumLarge: px(16),
  large: px(17),
  larger: px(20),
  secondLargest: px(24),
  largest: px(28),
} as const
export type TextSize = keyof typeof fontSize

const breakpoints = {
  xLarge: px(1280),
  large: px(960),
  mediumLarge: px(700),
  medium: px(600),
  small: px(500),
} as const
export type Breakpoint = keyof typeof breakpoints

const maxWidths = {
  content: px(500),
  paddedContent: px(460),
  column: px(375),
  paddedColumn: px(325),
  tab: px(180),
  sidebar: px(60),
  separator: px(40),
} as const
export type MaxWidth = keyof typeof maxWidths

const maxHeights = {
  shiftEndingImage: px(260),
  footer: px(60),
  sidebarItem: px(84),
  topbar: px(84),
  greetingOpen: px(256),
  greetingClosed: px(80),
} as const
export type MaxHeight = keyof typeof maxHeights

const fontFamilies = {
  decorative: "'Montserrat', sans-serif",
  content: "'Roboto', sans-serif",
} as const
export type TextStyle = keyof typeof fontFamilies

const animations = {
  spin: keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `,
  slideUp: keyframes`
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  `,
  //TODO: create a generic pulse effect that accepts any colors
  redPulse: keyframes`
    from {
      color: ${colors.red};
    }
    to {
      color: ${colors.grayLighter};
    }
  `,
} as const
export type Animation = keyof typeof animations

export const theme = {
  animations,
  breakpoints,
  maxWidths,
  maxHeights,
  nightMode: false,
  font: {
    ...fontSize,
    ...fontFamilies,
  },
  layout: {
    flexCenterH: css`
      display: flex;
      justify-content: center;
    `,
    flexRow: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    flexColumn: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    staticWidth: (width: string) => {
      return css`
        margin: 0 auto;
        width: ${width};
        box-sizing: border-box;
        @media (max-width: ${px(unPx(width) - 1)}) {
          max-width: 100%;
          width: 100%;
          min-width: 100%;
        }
      `
    },
    fluidWidth: (maxWidth: string) => {
      return css`
        margin: 0 auto;
        max-width: ${maxWidth};
        box-sizing: border-box;
        @media (max-width: ${px(unPx(maxWidth) - 1)}) {
          max-width: 100%;
          width: 100%;
          min-width: 100%;
        }
      `
    },
    fluidWidthMargin: (maxWidth: string, margin: string) => {
      return css`
        margin-left: ${margin};
        margin-right: ${margin};
        max-width: ${maxWidth};
        box-sizing: border-box;
        @media (max-width: ${px(unPx(maxWidth) - unPx(margin) * 2 - 1)}) {
          max-width: calc(100% - ${px(unPx(margin) * 2)});
          width: calc(100% - ${px(unPx(margin) * 2)});
          min-width: calc(100% - ${px(unPx(margin) * 2)});
        }
      `
    },
  },
  spacing: {
    sizes: spacing,
    all: (size: SpacingSize) => {
      return css`
        padding: ${spacing[size] ? spacing[size] : size};
      `
    },
    sides: (size: SpacingSize) => {
      return css`
        padding-left: ${spacing[size] ? spacing[size] : size};
        padding-right: ${spacing[size] ? spacing[size] : size};
      `
    },
    ends: (size: SpacingSize) => {
      return css`
        padding-top: ${spacing[size] ? spacing[size] : size};
        padding-bottom: ${spacing[size] ? spacing[size] : size};
      `
    },
    top: (size: SpacingSize) => {
      return css`
        padding-top: ${spacing[size] ? spacing[size] : size};
      `
    },
    bottom: (size: SpacingSize) => {
      return css`
        padding-bottom: ${spacing[size] ? spacing[size] : size};
      `
    },
    left: (size: SpacingSize) => {
      return css`
        padding-left: ${spacing[size] ? spacing[size] : size};
      `
    },
    right: (size: SpacingSize) => {
      return css`
        padding-right: ${spacing[size] ? spacing[size] : size};
      `
    },
  },
  text: (
    size: TextSize = 'normal',
    style: TextStyle = 'content',
    weight: string | number = '400'
  ) => {
    return css`
      font-family: ${fontFamilies[style] ? fontFamilies[style] : fontFamilies.content};
      font-size: ${fontSize[size] ? fontSize[size] : size};
      font-weight: ${weight || '400'};
      line-height: 1.5em;
    `
  },
  borderRadius: {
    button: px(3),
    pill: px(30),
    circle: px(100),
  },
  timeline: {
    dashingHeight: px(40),
    paddingLeftSmall: spacing.large,
    paddingLeftBig: px(70),
  },
  effects: {
    shadow: css`
      box-shadow: 0 2px 6px 1px rgba(${convert.hex.rgb(colors.blackAbsolute).join(',')}, 0.1);
    `,
    shadowNight: css`
      box-shadow: 0 2px 6px 1px rgba(${convert.hex.rgb(colors.white).join(',')}, 0.1);
    `,
    shadowLarge: css`
      box-shadow: 0 1px ${spacing.large} 0
        rgba(${convert.hex.rgb(colors.blackAbsolute).join(',')}, 0.1);
    `,
  },
  buttons: {
    border: `rgba(${convert.hex.rgb(colors.grayLight).join(',')}, 0.6)`, // e7e7e7
  },
} as const

export type MaybeColor = Color | '' | undefined

const convertColor = (names: Array<MaybeColor>): string => {
  const match = _.find(names, (c) => c && colors[c]) as MaybeColor
  return match ? colors[match] : ''
}

export const color = (name?: MaybeColor, fallback: MaybeColor = ''): string => {
  return convertColor([name, fallback])
}

export type Theme = {
  nightMode?: boolean
}

export const getColor = (
  theme: Theme,
  day: Array<MaybeColor>,
  night: Array<MaybeColor>
): string => {
  if (theme.nightMode) {
    return convertColor(night)
  }
  return convertColor(day)
}

export const defaultTextColor = (props: { theme?: Theme }) =>
  getColor(props.theme, ['black'], ['grayPink'])
export const subtleTextColor = (props: { theme?: Theme }) =>
  getColor(props.theme, ['grayDark'], ['grayPink'])

export const Padded = styled.div<PaddedProps>`
  ${(p) => theme.spacing.all(p.padding ? p.padding : 'large')};
`

export const Hr = styled.div`
  width: 100%;
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};
`
