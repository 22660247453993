import { useRouter } from 'found'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import {
  deleteSearchedShiftFromHistory,
  fetchShifts,
  getSearchedShiftSearchHistory,
  searchedShiftFromHistory,
} from '../../actions/api'
import ErrorText from '../../components/ErrorText'
import Page from '../../components/page/Page'
import SearchPageContainer from '../../components/search/SearchPageContainer'
import SearchPageContent from '../../components/search/SearchPageContent'
import ShiftSearch from '../../components/shift/ShiftSearch'
import moment from '../../lib/moment-fi'
import { isCommuterUser } from '../../lib/roleUtils'
import { ShiftSearchSource } from '../../types/Input'

const ShiftSearchPage = () => {
  const { t } = useTranslation()
  const { router } = useRouter()
  const dispatch = useTypedDispatch()

  const [trainNumber, setTrainNumber] = useState('')
  const [trainNumberActive, setTrainNumberActive] = useState(false)
  const [date, setDate] = useState(moment().format('DD.MM.YYYY'))
  const [dateActive, setDateActive] = useState(false)
  const [shiftId, setShiftId] = useState('')
  const [shiftIdActive, setShiftIdActive] = useState(false)
  const [startStation, setStartStation] = useState('')
  const [startStationActive, setStartStationActive] = useState(false)
  const [error, setError] = useState(undefined)

  const loadingShifts = useTypedSelector((state) => state.searchedShifts.loading)
  const shiftsError = useTypedSelector((state) => state.searchedShifts.error)
  const shiftsHistory = useTypedSelector((state) => state.searchedShifts.history)
  const user = useTypedSelector((state) => state.user)

  useEffect(() => {
    getSearchedShiftSearchHistory()
  }, [])

  const changeTrainNumber = (value: string) => {
    const matchPositiveNumber = /^[1-9]\d*$/
    if ((trainNumber !== '' && value === '') || matchPositiveNumber.test(value)) {
      setTrainNumber(value)
      setError(undefined)
      setShiftId('')
    } else {
      setError({ error: 'Junanumeron pitää olla positiivinen' })
    }
  }

  const changeDate = (value: moment.MomentInput) => {
    setDate(moment(value).format('DD.MM.YYYY'))
    setError(undefined)
  }

  const activateTrainNumber = () => {
    setTrainNumberActive(true)
    setDateActive(false)
    setStartStationActive(false)
    setShiftIdActive(false)
  }

  const activateDate = () => {
    setTrainNumberActive(false)
    setDateActive(true)
    setStartStationActive(false)
    setShiftIdActive(false)
  }

  const activateShiftId = () => {
    setTrainNumberActive(false)
    setDateActive(false)
    setStartStationActive(false)
    setShiftIdActive(true)
  }

  const activateStartStation = () => {
    setTrainNumberActive(false)
    setDateActive(false)
    setStartStationActive(true)
    setShiftIdActive(false)
  }

  const getShifts = (searchSource: ShiftSearchSource) => {
    const isValidShiftIdSearch = shiftId !== '' && date !== ''
    const isValidTrainNumberSearch = trainNumber !== '' && date !== '' && startStation !== ''
    const isValidCommuterTrainNumberSearch =
      trainNumber !== '' && date !== '' && (isCommuterUser(user) || searchSource === 'IVU')
    if (isValidTrainNumberSearch || isValidCommuterTrainNumberSearch) {
      dispatch(
        fetchShifts(date, trainNumber, startStation, shiftId, searchSource, () =>
          router.push(
            `/shiftInstructions/trainNumber=${trainNumber}&date=${date}&startStation=${startStation}`
          )
        )
      )
    } else if (isValidShiftIdSearch) {
      dispatch(
        fetchShifts(date, trainNumber, startStation, shiftId, searchSource, () =>
          router.push(`/shiftInstructions/shiftId=${shiftId}&date=${date}`)
        )
      )
    } else {
      setError(t('fillAllMandatoryFields'))
    }
  }

  if (error !== t(shiftsError)) {
    setError(t(shiftsError))
  }

  return (
    <Page overflowVisible>
      <SearchPageContainer>{error && <ErrorText>{error}</ErrorText>}</SearchPageContainer>
      <SearchPageContainer>
        <SearchPageContent>
          <ShiftSearch
            activateDate={() => activateDate()}
            activateShiftId={() => activateShiftId()}
            activateStartStation={() => activateStartStation()}
            activateTrainNumber={() => activateTrainNumber()}
            changeDate={(value) => changeDate(value)}
            changeTrainNumber={(value) => changeTrainNumber(value)}
            changeStartStation={(startStation: string) => {
              setStartStation(startStation)
              setError(undefined)
            }}
            changeShiftId={(shiftId: string) => {
              setShiftId(shiftId)
              setTrainNumber('')
              setError(undefined)
            }}
            fetchShifts={(searchSource) => getShifts(searchSource)}
            shiftHistory={shiftsHistory}
            searchedShiftFromHistory={searchedShiftFromHistory}
            deleteSearchedShiftFromHistory={deleteSearchedShiftFromHistory}
            loading={loadingShifts}
            router={router}
            setError={(error) => setError(error)}
            date={date}
            dateActive={dateActive}
            trainNumber={trainNumber}
            shiftId={shiftId}
            startStation={startStation}
            trainNumberActive={trainNumberActive}
            shiftIdActive={shiftIdActive}
            startStationActive={startStationActive}
            t={t}
            user={user}
          />
        </SearchPageContent>
      </SearchPageContainer>
    </Page>
  )
}

export default ShiftSearchPage
