import {
  DISABLE_VIRTUAL_TIME,
  FAST_FORWARD_VIRTUAL_TIME,
  NETWORK_CHANGE,
  NEW_APP_VERSION,
  NORMAL_SPEED_VIRTUAL_TIME,
  RESIZE_SCREEN,
  SystemAction,
  TICK_TIMER,
  TOGGLE_ADMIN_BAR,
  TOGGLE_NIGHT_MODE,
  TOGGLE_SHORT_NUMBERS,
  TOGGLE_TASK_ROW_INFO_DEBUG,
  UPDATE_OFFSET_VIRTUAL_TIME,
  UPDATE_REDIRECT,
} from '../actions/system'
import moment from '../lib/moment-fi'
import { width } from '../lib/screen'
import { SystemState } from '../types'
import { NetworkStatus } from '../types/States'

const initialState: SystemState = {
  adminBarCollapsed: true,
  nightMode: false,
  shortNumbers: false,
  network: window.navigator.onLine ? NetworkStatus.ON : NetworkStatus.OFF,
  newVersion: false,
  now: Date.now(),
  screenWidth: width(),
  virtualTime: {
    enabledAt: 0,
    offset: 0,
    multiplier: 1,
  },
  taskRowDebugEnabled: false,
  pushAlertDismissed: false,
}

const calculateVirtualOffset = (lastNow: number, newNow: number, multiplier: number): number => {
  const diff = moment(newNow).diff(moment(lastNow))
  return diff * multiplier
}

const systemReducer = (state: SystemState = initialState, action: SystemAction) => {
  let offset = 0,
    type: string

  switch (action.type) {
    case TICK_TIMER:
      // Virtual time advances clock with multiplier
      if (state.virtualTime.enabledAt > 0) {
        offset =
          state.virtualTime.offset +
          calculateVirtualOffset(state.now, action.payload.now, state.virtualTime.multiplier)
      }

      return {
        ...state,
        now: action.payload.now,
        virtualTime: { ...state.virtualTime, offset },
      }

    case DISABLE_VIRTUAL_TIME:
      return { ...state, virtualTime: { enabledAt: 0, multiplier: 1, offset: 0 } }

    case UPDATE_OFFSET_VIRTUAL_TIME:
      return {
        ...state,
        virtualTime: {
          ...state.virtualTime,
          enabledAt: state.virtualTime.enabledAt ? state.virtualTime.enabledAt : state.now,
          offset: action.payload.offset,
        },
      }

    case NORMAL_SPEED_VIRTUAL_TIME:
      return {
        ...state,
        virtualTime: {
          ...state.virtualTime,
          enabledAt: state.virtualTime.enabledAt ? state.virtualTime.enabledAt : state.now,
          multiplier: 1,
        },
      }

    case FAST_FORWARD_VIRTUAL_TIME:
      return {
        ...state,
        virtualTime: {
          ...state.virtualTime,
          enabledAt: state.virtualTime.enabledAt ? state.virtualTime.enabledAt : state.now,
          multiplier: state.virtualTime.multiplier * 2,
        },
      }

    case RESIZE_SCREEN:
      if (action.payload.width === state.screenWidth) {
        return state
      }

      return { ...state, screenWidth: action.payload.width }

    case TOGGLE_NIGHT_MODE:
      return { ...state, nightMode: !state.nightMode }

    case TOGGLE_SHORT_NUMBERS:
      return { ...state, shortNumbers: !state.shortNumbers }

    case TOGGLE_ADMIN_BAR:
      return { ...state, adminBarCollapsed: !state.adminBarCollapsed }

    case NETWORK_CHANGE:
      type = action.payload.type

      // ONLINE, always means network is ON
      if (type === 'ONLINE') {
        return { ...state, network: NetworkStatus.ON }

        // OFFLINE changes network's status to OFF
      } else if (type === 'OFFLINE') {
        return { ...state, network: NetworkStatus.OFF }
      }

      return state
    case NEW_APP_VERSION:
      return { ...state, newVersion: action.payload.exists }

    case UPDATE_REDIRECT:
      return { ...state, redirectAfterLoad: action.payload.status }

    case TOGGLE_TASK_ROW_INFO_DEBUG:
      return { ...state, taskRowDebugEnabled: !state.taskRowDebugEnabled }

    default:
      return state
  }
}

export default systemReducer
