import styled from '@emotion/styled'
import { Router } from 'found'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TypedDispatch } from '../..'
import { fetchAssemblies, fetchFindings, fetchTowingPatterns } from '../../actions/api'
import { fetchEquipmentsAction } from '../../actions/defect-common'
import {
  fetchIncidentsForTrainsAction,
  IncidentsTrainFilter,
  postIncidentFeedbackAction,
} from '../../actions/incidents'
import config from '../../config'
import {
  ASSEMBLIES_POLLING_RATE_MS,
  FETCH_FINDINGS_POLL_RATE_MS,
  NEXT_TASK_FINDINGS_POLLING_CUTOFF_MS,
} from '../../constants'
import moment from '../../lib/moment-fi'
import { formatDuration } from '../../lib/time'
import {
  getVehicleNumbers,
  shiftIsActive,
  shiftIsOver48hAway,
  shiftIsPassed,
  shiftSignInStatus,
} from '../../Selectors'
import { getColor, LAYOUT_LEFT_EDGE, px, subtleTextColor, theme } from '../../Theme'
import {
  AppState,
  Assembly,
  CrewNotice,
  Moment,
  ObservationMessage,
  Shift,
  ShiftNotice,
  TaskParams,
  UserState,
} from '../../types'
import { SizeNumberProps } from '../../types/App'
import ActionError from '../ActionError'
import { LiitoIncident, LiitoIncidentForFeedback } from '../incidents/types'
import NotificationBar from '../notification/NotificationBar'
import ObservationMessageList from '../observation-message/ObservationMessageList'
import TimetableRow from '../timetable/TimetableRow'
import DutyEvaluation from './DutyEvaluation'
import IncidentFeedback from './IncidentFeedback'
import ShiftFeedback from './ShiftFeedback'
import ShiftNoticeNotification from './ShiftNoticeNotification'
import ShiftSignIn from './ShiftSignIn'
import ShiftTasks from './ShiftTasks'

const Container = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  ${theme.spacing.all('small')};
  background-color: ${(p) => getColor(p.theme, ['white'], ['nightGray'])};
  min-width: ${theme.maxWidths.column};
`

const Row = styled.div`
  ${theme.spacing.bottom('small')};
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: ${theme.spacing.sizes.larger};
  width: 100%;
`

const Column = styled.div<Partial<SizeNumberProps>>`
  ${theme.layout.flexColumn};
  align-items: normal;
  flex: ${(p) => p.size || 1};
  margin: auto 0;
`

const ColumnFull = styled(Column)`
  display: block;
  width: 100%;
`

const Title = styled.div`
  ${theme.text()};
  color: ${subtleTextColor};
  width: 100px;
`

const StartTime = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  flex-grow: 1;
  padding-left: 23px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const Duration = styled.div`
  ${theme.text('normal', 'content')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['white'])};
`

const StartRow = styled.div`
  margin-left: ${px(LAYOUT_LEFT_EDGE)};
  margin-right: 12px;
  ${theme.spacing.bottom('small')};
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: ${theme.spacing.sizes.larger};
`

const EndRow = styled.div`
  margin-left: ${px(LAYOUT_LEFT_EDGE)};
  margin-right: 12px;
  ${theme.spacing.ends('small')};
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: ${theme.spacing.sizes.larger};
  flex-align: center;
`

type Props = {
  now: Moment
  shift: Shift
  isMobile: boolean
  reload: () => void
  active?: boolean
  signedIn?: boolean
  past?: boolean
  viewOnly?: boolean
  router: Router
  crewNotices?: Array<CrewNotice>
  observationMessages?: Array<ObservationMessage>
  fetchVehiclePatterns: () => void
  assembliesById?: Record<string, Assembly>
  assembliesError?: string
  assembliesLoading?: boolean
  taskParams?: TaskParams | null
  fetchAssemblies: (taskParams: TaskParams) => void
  towingPatternsLoading?: boolean
  user: UserState
  shiftNotices?: Array<ShiftNotice>
  fetchEquipments: () => void
  fetchIncidentsForTrains: (params: IncidentsTrainFilter[]) => void
  incidentsEnabled: boolean
  incidents: Array<LiitoIncident>
  incidentFeedbackEnabled: boolean
  incidentsWaitingForFeedback: Array<LiitoIncidentForFeedback>
  closeIncidentFeedback: (params: { incidentId: number; wasLinkClicked: boolean }[]) => void
  fetchFindings: (locIds: string[]) => void
}

function ShiftContainer({
  now,
  isMobile,
  shift,
  reload,
  signedIn,
  past,
  viewOnly,
  router,
  crewNotices,
  observationMessages,
  fetchVehiclePatterns,
  taskParams,
  fetchAssemblies,
  user,
  shiftNotices,
  fetchEquipments,
  fetchIncidentsForTrains,
  incidentsEnabled,
  incidents,
  incidentFeedbackEnabled,
  incidentsWaitingForFeedback,
  closeIncidentFeedback,
  fetchFindings,
}: Props) {
  const { t } = useTranslation()

  const fetchAssemblyChanges = () => {
    const validParams = taskParams
      ? taskParams.filter((param) => !param.endTime || moment().isBefore(moment(param.endTime)))
      : []
    if (validParams.length) {
      fetchAssemblies(validParams)
    }
  }

  useEffect(() => {
    fetchEquipments()
  }, [fetchEquipments])

  useEffect(() => {
    fetchVehiclePatterns()
    let handle: NodeJS.Timeout | undefined = undefined
    if (user.conductor || user.waiter) {
      fetchAssemblyChanges()
      handle = setInterval(() => {
        fetchAssemblyChanges()
      }, ASSEMBLIES_POLLING_RATE_MS)
    }

    return () => clearInterval(handle)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [user.conductor, fetchVehiclePatterns, JSON.stringify(taskParams), fetchAssemblies])
  /* eslint-enable react-hooks/exhaustive-deps */

  // fetch incidents related to shift
  useEffect(() => {
    if (incidentsEnabled) {
      if (shift.tasks) {
        const trainsByDay = Object.entries(
          shift.tasks.reduce(
            (byDate, task) => {
              if (
                !task.operatingDate ||
                !task.trainNumber ||
                //filter out passenger tasks and other invalid train numbers
                !task.trainNumber.trim().match(/^\d+$/)
              ) {
                return byDate
              }

              if (!byDate[task.operatingDate]) {
                byDate[task.operatingDate] = new Set()
              }

              byDate[task.operatingDate].add(task.trainNumber)
              return byDate
            },
            {} as Record<string, Set<string>>
          )
        ).map(([operatingDay, trainNumbers]) => ({
          operatingDay,
          trainNumbers: Array.from(trainNumbers),
        }))

        fetchIncidentsForTrains(trainsByDay)
      }
    }
  }, [shift.tasks, fetchIncidentsForTrains, incidentsEnabled])

  const fetchFindingsForAllTasks = shift.isCommuter && shift.tasks?.length && user.commuter_driver

  // initially, fetch findings for all tasks
  useEffect(() => {
    if (!fetchFindingsForAllTasks) return

    const locIds = new Set<string>()
    shift.tasks?.forEach((task) => {
      getVehicleNumbers(task).forEach((locId) => locIds.add(locId))
    })

    if (locIds.size) {
      fetchFindings(Array.from(locIds))
    }
  }, [fetchFindingsForAllTasks, shift.tasks, fetchFindings])

  // poll findings for next task
  useEffect(() => {
    if (!fetchFindingsForAllTasks) return

    const handle = setInterval(() => {
      if (!shift.tasks?.length) return

      const now = Date.now()
      const nextTask = shift.tasks.find((task) => new Date(task.taskStartDateTime).getTime() > now)

      if (
        nextTask &&
        new Date(nextTask.taskStartDateTime).getTime() - now < NEXT_TASK_FINDINGS_POLLING_CUTOFF_MS
      ) {
        const locIds = getVehicleNumbers(nextTask)
        if (locIds.length) fetchFindings(locIds)
      }
    }, FETCH_FINDINGS_POLL_RATE_MS)

    return () => clearInterval(handle)
  }, [fetchFindingsForAllTasks, shift.tasks, fetchFindings])

  return (
    <div>
      <Container>
        <Row>
          <ColumnFull>
            {shiftNotices && (
              <ShiftNoticeNotification
                shiftNotices={shiftNotices}
                marginBottom={theme.spacing.sizes.tiny}
              />
            )}
            {shift.notifications &&
            !shiftIsOver48hAway(now, shift) &&
            !shiftIsPassed(now, shift) ? (
              <NotificationBar
                markdown={shift.notifications}
                marginBottom={theme.spacing.sizes.tiny}
              />
            ) : undefined}
            {shift.isCommuter && observationMessages && observationMessages.length !== 0 && (
              <ObservationMessageList observationMessages={observationMessages} />
            )}
            {incidentsWaitingForFeedback.map((incident) => (
              <IncidentFeedback
                key={incident.incidentId}
                incident={incident}
                handleClose={(wasLinkClicked) =>
                  closeIncidentFeedback([{ incidentId: incident.incidentId, wasLinkClicked }])
                }
              />
            ))}
            <StartRow>
              <Title>{`(${shift.preparation} min)`}</Title>
              <StartTime>{moment(shift.startDateTime).format('HH:mm')}</StartTime>
              {!viewOnly && !past && !shift.isCommuter ? (
                <ShiftSignIn shift={shift} isMobile={isMobile} />
              ) : undefined}
              {(shift.dutyEvaluationPercentage || shift.dutyEvaluationPercentage === 0) && (
                <DutyEvaluation percentage={shift.dutyEvaluationPercentage} />
              )}
            </StartRow>
            {shift.error ? (
              <Row>
                <ActionError error={shift.error} action={reload} actionText="retry" />
              </Row>
            ) : undefined}
            <ShiftTasks
              shift={shift}
              router={router}
              notices={crewNotices}
              incidents={incidents}
              t={t}
            />
            <EndRow>
              <Title>{`(${shift.wrapUp} min)`}</Title>
              <StartTime>{moment(shift.endDateTime).format('HH:mm')}</StartTime>
              <Duration>{formatDuration(shift.workingTime || shift.duration)}</Duration>
            </EndRow>
            <TimetableRow full shift={shift} />
          </ColumnFull>
        </Row>
        {incidentFeedbackEnabled && !viewOnly && (
          <Row>
            <ColumnFull>
              <IncidentFeedback
                handleClose={(wasLinkClicked) =>
                  closeIncidentFeedback(
                    incidents.map(({ id }) => ({ incidentId: id, wasLinkClicked }))
                  )
                }
              />
            </ColumnFull>
          </Row>
        )}
        {signedIn && config.features.shiftFeedback ? (
          <Row>
            <ColumnFull>
              <ShiftFeedback shift={shift} isMobile={isMobile} past={past} />
            </ColumnFull>
          </Row>
        ) : undefined}
      </Container>
    </div>
  )
}

type PropsIn = {
  now: Moment
  shift: Shift
  viewOnly?: boolean
}

const mapStateToProps = (state: AppState, { now, shift, viewOnly }: PropsIn) => {
  const past = shiftIsPassed(now, shift)
  const active = !past && shiftIsActive(now, shift)

  const signInStatus = shiftSignInStatus(state)(shift.id)
  const signedIn = signInStatus.state === 'signed-in'

  const crewNotices =
    shift.crewNotices &&
    shift.crewNotices
      .map((cn) => state.crewNotices.byCrewNoticeId[cn.crewNoticeId])
      .sort((a, b) => moment(a.sentAt).valueOf() - moment(b.sentAt).valueOf())

  const observationMessages = state.observationMessages.observationMessages.filter(
    (message) =>
      moment(message.dutyDate).isSame(moment(shift.startDateTime), 'day') &&
      message.dutyShortName === shift.shiftId
  )

  const assembliesById = state.assemblies.byId
  const assembliesLoading = state.assemblies.loading
  const assembliesError = state.assemblies.error

  const taskParams =
    shift.tasks &&
    shift.tasks
      .filter((task) => !!task.trainNumber && Number.isInteger(parseInt(task.trainNumber, 10)))
      .filter((task) => !!task.trainNumber && !!task.taskStartDateTime && !!task.fromStation)
      .map((task) => ({
        trainNumber: task.trainNumber,
        operatingDateTime: task.taskStartDateTime,
        endTime: task.taskEndDateTime,
        ocp: task.fromStation,
      }))

  const towingPatternsLoading = state.towingPatterns.loading

  const user = state.user

  const shiftNotices = state.shiftNotices.notices

  const incidentsEnabled = state.incidents.incidentsEnabled

  const incidents = state.incidents.shiftIncidents

  const incidentsWaitingForFeedback = state.incidents.shiftIncidentsWaitingForFeedback

  const incidentFeedbackEnabled = incidents.some((incident) =>
    ['KESKITASO', 'VAKAVA'].includes(incident.disruptionLevel?.toUpperCase() || '')
  )

  return {
    active,
    signedIn,
    past,
    viewOnly,
    crewNotices,
    observationMessages,
    assembliesById,
    assembliesLoading,
    assembliesError,
    taskParams,
    towingPatternsLoading,
    user,
    shiftNotices,
    incidentsEnabled,
    incidents,
    incidentFeedbackEnabled,
    incidentsWaitingForFeedback,
  }
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  fetchVehiclePatterns: () => dispatch(fetchTowingPatterns()),
  fetchAssemblies: (params: TaskParams) => dispatch(fetchAssemblies(params)),
  fetchEquipments: () => dispatch(fetchEquipmentsAction(null)),
  fetchIncidentsForTrains: (params: IncidentsTrainFilter[]) =>
    dispatch(fetchIncidentsForTrainsAction(params)),
  closeIncidentFeedback: (params: { incidentId: number; wasLinkClicked: boolean }[]) =>
    dispatch(postIncidentFeedbackAction(params)),
  fetchFindings: (locIds: string[]) => dispatch(fetchFindings(locIds)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftContainer)
