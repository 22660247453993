import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { color } from '../../Theme'
import { TestPushMessage } from '../../types'

const TestMessagesContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const TestMessageBox = styled('div')(({ theme }) => ({
  background: color('lightGreen'),
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}))

const TestMessageItem = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
}))

type TestMessagesProps = {
  testMessages: TestPushMessage[]
}

const TestMessages = ({ testMessages }: TestMessagesProps) => {
  const { t } = useTranslation()

  const latestMessages = [...testMessages]
    .sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime())
    .slice(0, 3)

  return (
    <TestMessagesContainer>
      {latestMessages.map((msg) => (
        <TestMessageBox key={msg.messageId}>
          <TestMessageItem>
            <Typography variant="body2">
              {t('deviceManagement.testMessageRequested')}:{' '}
              {format(new Date(msg.sentAt), 'dd.MM.yyyy HH.mm')}
            </Typography>
            {msg.receivedAt ? (
              <Typography variant="body2">
                {t('deviceManagement.testMessageReceived')}:{' '}
                {format(new Date(msg.receivedAt), 'dd.MM.yyyy HH.mm')}
              </Typography>
            ) : (
              <Typography variant="body2">{t('deviceManagement.notReceivedYet')}</Typography>
            )}
          </TestMessageItem>
        </TestMessageBox>
      ))}
    </TestMessagesContainer>
  )
}

export default TestMessages
