import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { Device } from '../../types/Input'
import DeviceCard from './DeviceCard'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: 0,
}))

const HeadersRow = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '38% 32% 20% 10%',
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  marginBottom: theme.spacing(1),
}))

const HeaderItem = styled('div')(({ theme }) => ({
  textAlign: 'left',
  ...theme.typography.body1,
  color: theme.palette.mode === 'dark' ? 'white' : theme.palette.text.primary,
  whiteSpace: 'nowrap',
  marginTop: theme.spacing(1),
  fontWeight: 'bold',
}))

type DeviceListProps = {
  devices: Device[]
  expandedDeviceId: string | null
  handleExpand: (deviceId: string) => void
  currentDeviceId: string | null
  canBlockMessages: boolean
}

const DeviceList = ({
  devices,
  expandedDeviceId,
  handleExpand,
  currentDeviceId,
  canBlockMessages,
}: DeviceListProps) => {
  const { t } = useTranslation()

  return (
    <Container>
      <HeadersRow>
        <HeaderItem>{t('deviceManagement.device')}</HeaderItem>
        <HeaderItem>{t('deviceManagement.lastLoggedIn')}</HeaderItem>
        <HeaderItem>{t('deviceManagement.notifications')}</HeaderItem>
        <HeaderItem></HeaderItem>
      </HeadersRow>
      {devices.map((device) => (
        <DeviceCard
          key={device.deviceId}
          device={device}
          isExpanded={expandedDeviceId === device.deviceId}
          onExpand={() => handleExpand(device.deviceId)}
          isCurrentDevice={device.deviceId === currentDeviceId}
          canBlockMessages={canBlockMessages}
        />
      ))}
    </Container>
  )
}

export default DeviceList
