import { Button, Checkbox, Input, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { actAsUser } from '../../actions/api'
import { firstSuccess, getSessionFromDB } from '../../lib/data'
import { Session } from '../../types'
import DevSection from './DevSection'
import InputRow, { inputField } from './InputRow'

const AssumeRoles = (): JSX.Element => {
  const [number, setNumber] = useState(useTypedSelector((state) => state.user.number))
  const [isCommuter, setIsCommuter] = useState(
    useTypedSelector((state) => !!state.user.actAsCommuter)
  )
  const [isMaintenance, setIsMaintenance] = useState(
    useTypedSelector((state) => !!state.user.actAsMaintenance)
  )
  const [isServiceDriver, setIsServiceDriver] = useState(
    useTypedSelector((state) => !!state.user.actAsServiceDriver)
  )
  const [commuterManeger, setCommuterManeger] = useState(
    useTypedSelector((state) => !!state.user.commuter_manager)
  )
  const [logisticsDriver, setLogisticsDriver] = useState(
    useTypedSelector((state) => !!state.user.logistics_driver)
  )
  const [other, setOther] = useState(useTypedSelector((state) => !!state.user.other))

  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const actAs = (session: Session) =>
    dispatch(actAsUser(number, isCommuter, isMaintenance, isServiceDriver, session, false))

  const handleSubmit = async () => {
    const session = await firstSuccess([getSessionFromDB()])
    actAs({
      ...session,
      commuter_manager: commuterManeger,
      logistics_driver: logisticsDriver,
      other,
    })
  }

  return (
    <DevSection>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        {t('role')}
      </Typography>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('userNumber')}
        </Typography>
        <Input
          sx={{ ...inputField, maxWidth: '30%' }}
          disableUnderline
          value={number}
          type="text"
          onChange={(e) => {
            setNumber(e.target.value)
          }}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('commuterPersonnel')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={isCommuter}
          value={isCommuter}
          onChange={(e) => setIsCommuter(!isCommuter)}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('maintenancePersonnel')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={isMaintenance}
          value={isMaintenance}
          onChange={(e) => setIsMaintenance(!isMaintenance)}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('serviceDriverPersonnel')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={isServiceDriver}
          value={isServiceDriver}
          onChange={(e) => setIsServiceDriver(!isServiceDriver)}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('sessionRole.commuter_manager')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={commuterManeger}
          value={commuterManeger}
          onChange={(e) => setCommuterManeger(!commuterManeger)}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('sessionRole.logistics_driver')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={logisticsDriver}
          value={logisticsDriver}
          onChange={(e) => setLogisticsDriver(!logisticsDriver)}
        />
      </InputRow>
      <InputRow>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('sessionRole.other')}
        </Typography>
        <Checkbox
          sx={{ padding: 0 }}
          checked={other}
          value={other}
          onChange={(e) => setOther(!other)}
        />
      </InputRow>
      <Button variant="submitButtonFullWidth" onClick={() => handleSubmit()}>
        <Typography variant="button">{t('set')}</Typography>
      </Button>
    </DevSection>
  )
}

export default AssumeRoles
