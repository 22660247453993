import { format } from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { fetchTrainEquipmentInformationAction } from '../../actions/train-equipment-information'
import {
  filterAndGroupTrainEquipmentInfo,
  generateEquipmentInfoKey,
} from '../../lib/restaurantServiceUtils'
import { selectTrainEquipmentInfoByKey } from '../../reducers/trainEquipmentInfoSlice'
import { Task } from '../../types'
import { Text } from './TaskDetails'

type RestaurantServicesProps = {
  task: Task
  type: string // to -> toTrain, from -> fromTrain
}

export const RestaurantServices = ({ task, type }: RestaurantServicesProps) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const user = useTypedSelector((state) => state.user)

  const trainEquipmentInfo = useTypedSelector((state) =>
    selectTrainEquipmentInfoByKey(state, generateEquipmentInfoKey(task))
  )

  useEffect(() => {
    if (user.waiter) {
      if (!trainEquipmentInfo) {
        dispatch(
          fetchTrainEquipmentInformationAction({
            trainNumber: task.trainNumber,
            fromStation: task.fromStation,
            toStation: task.toStation,
            startDateTime: task.taskStartDateTime,
            endDateTime: task.taskEndDateTime,
          })
        )
      }
    }
  }, [
    dispatch,
    task.fromStation,
    task.taskEndDateTime,
    task.taskStartDateTime,
    task.toStation,
    task.trainNumber,
    trainEquipmentInfo,
    user,
  ])

  const groupedByTrainAndType = filterAndGroupTrainEquipmentInfo(trainEquipmentInfo, type)

  return (
    <>
      {Object.entries(groupedByTrainAndType).map(([trainKey, equipments]) => (
        <div key={trainKey}>
          {Object.entries(equipments).map(([wagonKey, equipment]) => (
            <div key={wagonKey}>
              <Text type={type}>
                {`${equipment.count} ${t(
                  `restaurantType.${equipment.equipmentType.toLowerCase()}`
                )} ${equipment.wagonCode}`}
              </Text>
            </div>
          ))}
          <Text type={type}>
            {`${t(`${type}Train`)} ${Object.values(equipments)[0].relatedTrainNumber} `}
            <strong>{`(${format(
              new Date(Object.values(equipments)[0].timestamp),
              'HH:mm'
            )})`}</strong>
          </Text>
        </div>
      ))}
    </>
  )
}
